import { Card, CardBody, CardHeader } from 'reactstrap';

import AccessContext from '../../contexes/AccessContext';
import PropTypes from 'prop-types';
import React from 'react';
import Section from '../Section';
import Select from 'react-select';
import TagNameContext from '../../contexes/TagNameContext';
import styled from 'styled-components';
import { usableAsBaseAudience } from '../../utils/audiences';

const BaseAudienceWrapper = styled.div`
  margin-bottom: 1.25rem;
`;

class BaseAudience extends React.Component {
  static propTypes = {
    audienceSelected: PropTypes.func.isRequired,
    audiences: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    baseAudience: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  };

  render() {
    return (
      <Section>
        <Card>
          <CardHeader>Base audience</CardHeader>
          <CardBody>
            <BaseAudienceWrapper>
              <AccessContext.Consumer>
                {({ readOnly, clientPatterns }) =>
                  readOnly ? (
                    <p>
                      {this.props.baseAudience && this.props.baseAudience.label}
                    </p>
                  ) : (
                    <TagNameContext.Consumer>
                      {(inputs) => (
                        <Select
                          name={
                            inputs &&
                            inputs.baseAudience &&
                            inputs.baseAudience.tagName
                          }
                          value={this.props.baseAudience}
                          onChange={this.props.audienceSelected}
                          options={usableAsBaseAudience(
                            this.props.audiences,
                            clientPatterns
                          )}
                        />
                      )}
                    </TagNameContext.Consumer>
                  )
                }
              </AccessContext.Consumer>
            </BaseAudienceWrapper>
          </CardBody>
        </Card>
      </Section>
    );
  }
}

export default BaseAudience;
