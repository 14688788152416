import { ColorsHelper } from 'happy-react-lib';
import React from 'react';
import find from 'lodash/find';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import join from 'lodash/join';
import map from 'lodash/map';
import styled from 'styled-components';
import { utils } from 'happy-react-lib';

const { intersperse } = utils;

function hydrate(condition, questions) {
  if (questions.length > 0) {
    const question = find(questions, q => q.id === condition.question);

    if (question) {
      const options = map(condition.options, optionId => {
        const option = find(
          question.available_options,
          opt => opt.code === optionId
        );

        return (option && option.name) || 'Unknown';
      });
      const suffixes = map(condition.suffixes, suffixId => {
        const suffix = find(question.suffixes, opt => opt.id === suffixId);

        return (suffix && suffix.name) || 'Unknown';
      });

      return {
        question: question.name,
        options: options,
        suffixes: suffixes,
        not: condition.not
      };
    }
  }

  return condition;
}

const AudienceExpressionOperator = styled.div`
  display: table;
  padding: 2px 13px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #fff;
  text-transform: uppercase;
  color: #ff0000;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  position: relative;
  font-size: 0.75rem;

  marginleft: ${({ depth }) => `${depth * 5}px`};
  color: ${({ color }) => color};

  &:before {
    content: '';
    position: absolute;
    height: 27px;
    width: 2px;
    background: #ccc;
    left: 15px;
    top: -11px;
    height: 10px;
  }

  &:after {
    content: '';
    position: absolute;
    height: 27px;
    width: 2px;
    background: #ccc;
    left: 15px;
    bottom: -11px;
    height: 10px;
  }
`;

const ExpressionContainer = styled.div`
  padding: 5px;
  border-radius: 0.3rem;
  background-color: ${({ color }) => color};
`;

const renderAudienceExpression = (
  expression,
  questions,
  color,
  depth = 0,
  position = 0
) => {
  let content = '...';

  if (expression) {
    if (expression.and || expression.or) {
      const nodes = map(expression.and || expression.or, (path, pathIndex) =>
        renderAudienceExpression(path, questions, color, depth + 1, pathIndex)
      );
      const interComponent = index => (
        <AudienceExpressionOperator
          key={`inter-${depth}-${index}`}
          depth={depth}
          color={color}
        >
          {expression.and ? 'AND' : 'OR'}
        </AudienceExpressionOperator>
      );
      content = intersperse(nodes, interComponent);
    } else {
      const sentence = hydrate(expression, questions);
      const displaySuffixes = sentence.suffixes && sentence.suffixes.length > 0;
      content = (
        <span>
          <b>{sentence.question}</b>
          :&nbsp;
          {sentence.not ? 'not ' : ''}
          {join(sentence.options, ', ')}
          {displaySuffixes ? ` [${join(sentence.suffixes, ' / ')}]` : ''}
        </span>
      );
    }

    if (depth === 0) {
      return <div key={`div-${position}-${depth}`}>{content}</div>;
    } else {
      return (
        // Use below audience color for backgroundColor
        <ExpressionContainer
          key={`panel-${position}-${depth}`}
          color={ColorsHelper.colorAlpha(color, depth / 25)}
        >
          {content}
        </ExpressionContainer>
      );
    }
  }
};

const AudienceExpression = ({ data, color }) => {
  const { loading, error, questions, audience } = data;

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <React.Fragment>
      {renderAudienceExpression(
        JSON.parse(audience.rawExpression),
        questions,
        color
      )}
    </React.Fragment>
  );
};

// Thanks to apollo, this query will be done only once
const QUERY = gql`
  query($audienceId: Int!) {
    questions {
      id
      name
      suffixes {
        id
        name
      }
      available_options {
        code
        name
      }
    }

    audience(id: $audienceId) {
      name
      rawExpression
    }
  }
`;

export default graphql(QUERY, {
  options: ({ audience }) => ({
    variables: {
      audienceId: audience.id
    }
  })
})(AudienceExpression);
