import { ButtonGroup, CardText, CardTitle } from 'reactstrap';

import EditOnly from '../EditOnly';
import MethodologyText from './MethodologyText';
import OptimizationScoreModal from '../OptimizationScoreModal';
import PropTypes from 'prop-types';
import React from 'react';
import ReadOnly from '../ReadOnly';
import Section from '../Section';
import TacticNotes from '../TacticNotes';
import TouchpointsSelectionList from '../TouchpointsSelectionList';
import loadTouchpoints from '../../queries/touchpoints';
import touchpointLabelMatcher from '../../utils/touchpoint_label_matcher';
import uniqBy from 'lodash/uniqBy';

// We use a textarea to transfert data to server *but* we use css to hide it. It's not a input[type=hidden] because of potential breaking characters like ", ', ...

const RATIONALE_INDEX = 0;
const THRESHOLD_INDEX = 1;
const BENCHMARKS_INDEX = 2;

const RATIONALE_PLACEHOLDER =
  'No specific rationales for this tactic. Please review the optimization score of the selected touchpoints.';
const THRESHOLD_PLACEHOLDER = 'Please click on each actionable touchpoints for more details.';
const BENCHMARKS_PLACEHOLDER =
  'No specific benchmarks for this tactic, please review the performance forecast in the detailed media plan below.';

const NOTES = [
  {
    id: RATIONALE_INDEX,
    label: 'Rationales',
    field: 'rationale',
  },
  {
    id: THRESHOLD_INDEX,
    label: 'Media mix and investment thresholds',
    field: 'threshold',
  },
  {
    id: BENCHMARKS_INDEX,
    label: 'Relevant benchmarks',
    field: 'benchmarks',
  },
];

class TouchpointsSelection extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    initialRationaleContent: PropTypes.string,
    initialThresholdContent: PropTypes.string,
    initialBenchmarksContent: PropTypes.string,
    selection: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      touchpoints: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          icon: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  state = {
    touchpointsEvaluationOpened: false,
    rationale: null,
    threshold: null,
    benchmarks: null,
    visibleText: NOTES[0].id,
  };

  constructor(props) {
    super(props);
    this.state.rationale = props.initialRationaleContent || RATIONALE_PLACEHOLDER;
    this.state.threshold = props.initialThresholdContent || THRESHOLD_PLACEHOLDER;
    this.state.benchmarks = props.initialBenchmarksContent || BENCHMARKS_PLACEHOLDER;
  }

  setRationaleTextareaRef = (ref) => {
    this.rationaleTextareaRef = ref;
    if (this.rationaleTextareaRef) {
      this.rationaleTextareaRef.value = this.state.rationale;
    }
  };
  setRationaleContent = (rationale) => {
    this.setState({ rationale });
    if (this.rationaleTextareaRef) {
      this.rationaleTextareaRef.value = rationale;
    }
  };
  setThresholdTextareaRef = (ref) => {
    this.thresholdTextareaRef = ref;
    if (this.thresholdTextareaRef) {
      this.thresholdTextareaRef.value = this.state.threshold;
    }
  };
  setThresholdContent = (threshold) => {
    this.setState({ threshold });
    if (this.thresholdTextareaRef) {
      this.thresholdTextareaRef.value = threshold;
    }
  };
  setBenchmarksTextareaRef = (ref) => {
    this.benchmarksTextareaRef = ref;
    if (this.benchmarksTextareaRef) {
      this.benchmarksTextareaRef.value = this.state.benchmarks;
    }
  };
  setBenchmarksContent = (benchmarks) => {
    this.setState({ benchmarks });
    if (this.benchmarksTextareaRef) {
      this.benchmarksTextareaRef.value = benchmarks;
    }
  };
  onTextChange = (id) => {
    switch (id) {
      case RATIONALE_INDEX:
        return this.setRationaleContent;
      case THRESHOLD_INDEX:
        return this.setThresholdContent;
      case BENCHMARKS_INDEX:
        return this.setBenchmarksContent;

      default:
        break;
    }

    return null;
  };

  render() {
    const { data, selection } = this.props;
    if (data.loading) {
      return null;
    }
    const errors = [];
    const touchpoints = uniqBy(
      selection.map((s) => {
        const t = data.touchpoints.find((t) => touchpointLabelMatcher(s.label, t.label));
        if (t) {
          return t;
        }
        errors.push(s.label);
        return null;
      }),
      'id'
    );

    if (errors.length > 0) {
      return (
        <Section>
          <CardTitle tag="h5">Touchpoints</CardTitle>
          <CardText tag="div">
            <div className="alert alert-danger">
              Some touchpoints are not available:
              <ul className="list-inline">
                {errors.map((msg, index) => (
                  <li className="list-inline-item" key={index}>
                    {msg}
                  </li>
                ))}
              </ul>
            </div>
          </CardText>
        </Section>
      );
    }

    return (
      <Section>
        <CardTitle tag="h5">Touchpoints</CardTitle>
        <ButtonGroup className="mb-3">
          <OptimizationScoreModal allTouchpoints={data.touchpoints} selectedTouchpoints={touchpoints} />
          <MethodologyText modal={true} />
        </ButtonGroup>
        <CardText tag="div">
          <TouchpointsSelectionList allTouchpoints={data.touchpoints} selectedTouchpoints={touchpoints} />
          <ReadOnly>
            <TacticNotes
              notes={NOTES.map((text) => ({
                name: text.label,
                content: this.state[text.field],
              }))}
            />
          </ReadOnly>
          <EditOnly>
            <React.Fragment>
              <textarea
                style={{ display: 'none' }}
                ref={this.setRationaleTextareaRef}
                name={`campaign_plan[campaign_plan_tactics_attributes][${this.props.index}][rationale_content]`}
              />
              <textarea
                style={{ display: 'none' }}
                ref={this.setThresholdTextareaRef}
                name={`campaign_plan[campaign_plan_tactics_attributes][${this.props.index}][threshold_content]`}
              />
              <textarea
                style={{ display: 'none' }}
                ref={this.setBenchmarksTextareaRef}
                name={`campaign_plan[campaign_plan_tactics_attributes][${this.props.index}][benchmarks_content]`}
              />
            </React.Fragment>
            <TacticNotes
              notes={NOTES.map((text) => ({
                name: text.label,
                content: this.state[text.field],
                onChange: this.onTextChange(text.id),
              }))}
            />
          </EditOnly>
        </CardText>
      </Section>
    );
  }
}

export default loadTouchpoints(TouchpointsSelection);
