import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import React from 'react';

require('highcharts/modules/funnel')(Highcharts);

const grayColors = [
  '#F5F5F5', // 100
  '#E5E5E5', // 200
  '#A3A3A3', // 400
  '#525252', // 600
  '#171717', // 900
];

function FunnelChart({ data }) {
  const config = {
    chart: { type: 'funnel', height: '450', marginTop: 0 },
    title: { text: '' },
    colors: grayColors,
    plotOptions: {
      series: {
        states: {
          hover: { enabled: false },
          inactive: {
            opacity: 1,
          },
        },
        dataLabels: {
          enabled: true,
          format: '{point.y:,.0f} {point.name}',
        },
        center: ['40%', '50%'],
        neckWidth: '30%',
        neckHeight: '25%',
        width: '80%',
      },
    },
    tooltip: {
      enabled: false,
      headerFormat: '<b>{series.name}</b><br/>',
      pointFormat: '{point.name}: {point.y:,.0f}',
    },
    series: [
      {
        name: 'Estimated Delivery',
        data,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={config} />;
}

FunnelChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default FunnelChart;
