import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';

const AUDIENCES_QUERY = gql`
  query audiences {
    audiences(filtered: true) {
      id
      name
    }
  }
`;

export default graphql(AUDIENCES_QUERY);
