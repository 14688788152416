import HeatMap from '../../components/HeatMap/Chart';
import React from 'react';
import numeral from 'numeral';

export default function HeatMapPreview() {
  const props = {
    xCategories: [
      'Search',
      'Social Media',
      'Video',
      'Radio Ecosystem',
      'Display',
    ],
    yCategories: [
      'Search',
      'Social Media',
      'Video',
      'Radio Ecosystem',
      'Display',
    ],
    tooltipFormatter,
    data: [
      [0, 0, 93600],
      [0, 1, 93600],
      [0, 2, 41600],
      [1, 0, 161100],
      [1, 1, 125000],
      [1, 2, 109200],
      [2, 0, 100000],
      [2, 1, 135000],
      [3, 0, 45000],
      [3, 1, 45000],
      [4, 0, 46100],
      [4, 1, 86400],
      [4, 2, 109200],
    ],
  };

  return <HeatMap {...props} />;
}

function tooltipFormatter() {
  const xLabel = this.series.xAxis.categories[this.point.x];
  const yLabel = this.series.yAxis.categories[this.point.y];
  const amount = numeral(this.point.value).format('$0,0');

  return `<b>${amount}</b> invested in "${xLabel}" through "${yLabel}"`;
}
