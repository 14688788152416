import { Number } from 'happy-react-lib';
import PropTypes from 'prop-types';
import React from 'react';

export default function EstimatedDelivery(props) {
  const {
    contacts,
    viewableContacts,
    viewableContactsOnTarget,
    deduplicated,
  } = props;

  return (
    <div>
      <p>
        We gather availability and cost of relevant, premium and safe
        inventories across publishers.
        <br />
        <b>{<Number value={contacts} />} contacts</b>
      </p>
      <p>
        We set the viewability level based on the nature of each touchpoints,
        historical benchmarks for selected ad formats and/or specific
        requirements from the brief.
        <br />
        <b>{<Number value={viewableContacts} />} viewable contacts</b>
      </p>
      <p style={{ marginBottom: '2.5rem' }}>
        We define a realistic on target delivery rate, leveraging available
        device data and contextual filters.
        <br />
        <b>
          {<Number value={viewableContactsOnTarget} />} viewable contacts on
          target
        </b>
      </p>
      <p>
        We assess what is the{' '}
        <b>optimal frequency to achieve across touchpoints</b>, and optimize
        trading accordingly during the campaign via trackable overlaps, average
        frequency monitoring and capping.
      </p>
      <p>
        <b>
          {<Number value={deduplicated} />} viewable contacts on target exposed
          with optimal frequency deduplicated across touchpoints.
        </b>
      </p>
    </div>
  );
}

EstimatedDelivery.propTypes = {
  contacts: PropTypes.number.isRequired,
  viewableContacts: PropTypes.number.isRequired,
  viewableContactsOnTarget: PropTypes.number.isRequired,
  viewableContactsOnTargetWithOptimalFrequency: PropTypes.number.isRequired,
  deduplicated: PropTypes.number.isRequired,
};
