import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';

const ACTIVATION_STRATEGIES_QUERY = gql`
  query activationStrategies {
    activationStrategies {
      id
      title
      description
      estimatedOptimalFrequency
    }
  }
`;

export default graphql(ACTIVATION_STRATEGIES_QUERY);
