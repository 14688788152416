import { CardText, CardTitle } from 'reactstrap';

import Amount from '../Amount';
import EditOnly from '../EditOnly';
import { Percentage } from 'happy-react-lib';
import PropTypes from 'prop-types';
import React from 'react';
import ReadOnly from '../ReadOnly';
import Section from '../Section';
// TODO: we load to get the label. Should be exposed to INITIAL_STATE
import loadActivationStrategies from '../../queries/activation_strategies';

function TacticInvestment({ investment, budgetSplit, activationStrategyId }) {
  let FocusSentence = '.';
  if (activationStrategyId > 0) {
    FocusSentence = loadActivationStrategies(OptimizationFocus);
  }

  return (
    <Section>
      <CardTitle tag="h5">Investment</CardTitle>
      <CardText tag="div">
        With{' '}
        <b>
          <Amount value={investment} />
        </b>
        , this tactic represents{' '}
        <b>
          <Percentage value={budgetSplit} />
        </b>{' '}
        of the total campaign investment
        <EditOnly>.</EditOnly>
        {activationStrategyId ? (
          <ReadOnly>
            <FocusSentence activationStrategyId={activationStrategyId} />
          </ReadOnly>
        ) : (
          '.'
        )}
      </CardText>
    </Section>
  );
}

function OptimizationFocus({ data, activationStrategyId }) {
  if (data.loading) {
    return '...';
  }
  const focus = data.activationStrategies.find(
    (s) => s.id === activationStrategyId
  );
  if (focus) {
    return (
      <span>
        , and the optimization focus is <b>{focus.title}</b>.
      </span>
    );
  }

  return <span>No strategy found</span>;
}

TacticInvestment.propTypes = {
  investment: PropTypes.number.isRequired,
  budgetSplit: PropTypes.number.isRequired,
  activationStrategyId: PropTypes.number,
};

export default TacticInvestment;
