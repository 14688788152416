import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const BigNumber = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 3em;
`;

export default function TrueTargetRatingPoint({ rating }) {
  return (
    <section>
      <header>
        <h5>True Target Rating Point</h5>
      </header>
      <BigNumber>{rating}</BigNumber>
      <p className="mb-0" style={{ fontSize: '11px' }}>
        The "Target Rating Point" is a metric used in marketing and advertising
        to indicate the percentage of the target audience reached by a campaign
        through a communication medium. We compute the "<b>True</b> Target
        Rating Point" by taking in account{' '}
        <b>viewability, on target delivery</b> and <b>overlap across media</b>.
      </p>
    </section>
  );
}

TrueTargetRatingPoint.propTypes = {
  rating: PropTypes.number.isRequired,
};
