export const toOption = (audience) => ({
  value: audience.id,
  label: audience.name,
});

export const toSelectOptions = (audiences) => audiences.map(toOption);

export const usableAsTargetAudience = (audiences, clientPatterns = []) => {
  return audiences.filter((a) => {
    return applyNames(a, clientPatterns);
  });
};

const TRANSVERSE_BASE_PATTERNS = ['GenPop'];

export const usableAsBaseAudience = (audiences, clientPatterns = []) => {
  return audiences.filter((a) => {
    const names = TRANSVERSE_BASE_PATTERNS.concat(clientPatterns);
    return applyNames(a, names);
  });
};

function applyNames(audience, names) {
  const patterns = names.join('|');
  const re = new RegExp(`^${patterns}>.*`, 'i');
  return audience.name ? audience.name.match(re) : audience.label.match(re);
}
