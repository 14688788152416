import { ColorsHelper } from 'happy-react-lib';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import React from 'react';
import { COLOR_PALETTE } from '../../utils/colors';
import { isFinancialLabel } from './index';

require('highcharts/modules/sunburst')(Highcharts);

class SunburstChart extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
  };

  // we return false because this component will live by itself once created
  // a re upload of MediaPlan will force a refresh of Detailed plan so we are good
  shouldComponentUpdate = function (nextProps, nextState) {
    return !this.props.metric || this.props.metric.id !== nextProps.metric.id;
  };

  render() {
    const { onClick, metric } = this.props;

    const config = {
      title: { text: '' },
      credits: { enabled: false },
      chart: {
        height: '650px',
      },
      colors: COLOR_PALETTE,
      plotOptions: {
        sunburst: {
          dataLabels: {
            enabled: false,
            color: '#293113',
            style: {
              textOutline: null, // on this chart useHTML doesn't work. We need to set this option manually
            },
          },
          allowDrillToNode: true,
          allowPointSelect: true,
          point: {
            events: {
              select: function (e) {
                onClick(e.target);

                return true;
              },
            },
          },
        },
      },
      tooltip: {
        backgroundColor: 'rgba(255,255,255, 1)',
        useHTML: true,
        valuePrefix: isFinancialLabel(metric.label) ? '$' : '',
        valueSuffix: isFinancialLabel(metric.label) ? '' : ` ${metric.label.toLowerCase()}`,
      },
      series: [
        {
          type: 'sunburst',
          data: this.props.data,
          cursor: 'pointer',
          drillUpButton: {
            text: '< Back',
          },
          levels: this.props.levelConfiguration,
        },
      ],
    };

    return <HighchartsReact highcharts={Highcharts} options={config} ref="chart" />;
  }
}

export default SunburstChart;
