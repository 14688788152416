import { Button, ButtonGroup } from 'reactstrap';

import React from 'react';

const FlattenToolbar = ({ flattened, onClick }) => (
  <ButtonGroup>
    <Button outline active={flattened} onClick={() => onClick(true)}>
      Summary
    </Button>
    <Button outline active={!flattened} onClick={() => onClick(false)}>
      Detailed
    </Button>
  </ButtonGroup>
);

export default FlattenToolbar;
