import styled from 'styled-components';

const ChartTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  color: #212529;
  line-height: 1.2;
  text-align: left;
  margin-bottom: 0.75rem;
  font-family: var(--font-family-sans-serif);
`;

export default ChartTitle;
