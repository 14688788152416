import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import React from 'react';

const ScatterChart = (props) => {
  const config = {
    chart: {
      type: 'scatter',
      height: '400px',
      zoomType: 'xy',
    },
    title: { text: props.title },
    plotOptions: {
      series: {
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          useHTML: true,
          shadow: false,
          style: {
            textShadhow: null,
            fontSize: '9px',
            color: '#000',
          },
          format: '{point.name}',
        },
      },
    },
    xAxis: {
      title: { text: props.xAxisTitle },
      reversed: !!props.xAxisReversed,
      labels: {
        format: `${props.xPreUnit}{value}${props.xPostUnit}`,
      },
    },
    yAxis: {
      title: { text: props.yAxisTitle },
      labels: {
        format: `{value}${props.yUnit}`,
      },
    },
    tooltip: {
      borderWidth: 0,
      backgroundColor: 'rgba(255,255,255,0)',
      shadow: false,
      useHTML: true,
      headerFormat: '<table>',
      footerFormat: '</table>',
      pointFormatter: props.pointFormatter,
    },
    series: [
      {
        data: props.data,
      },
    ],
  };

  return (
    <div className="highcharts-bubble-chart">
      <HighchartsReact highcharts={Highcharts} options={config} />
    </div>
  );
};

ScatterChart.propTypes = {
  title: PropTypes.string,
  xAxisTitle: PropTypes.string,
  yAxisTitle: PropTypes.string,
  onBubbleClick: PropTypes.func.isRequired,
  xPreUnit: PropTypes.string,
  xPostUnit: PropTypes.string,
  yUnit: PropTypes.string,
  pointFormatter: PropTypes.func.isRequired,
  xAxisReversed: PropTypes.bool,
};

ScatterChart.defaultProps = {
  xPreUnit: '',
  xPostUnit: '',
  yUnit: '',
};

export default ScatterChart;
