import { Button, ButtonGroup } from 'reactstrap';

import AccessContext from '../../contexes/AccessContext';
import React from 'react';
import RequestChangeModal from './RequestChangeModal';
import ValidationModal from './ValidationModal';

class StatusBar extends React.Component {
  state = {
    requestChangeModalOpened: false,
    validateModalOpened: false
  };

  toggleRequestChangeModal = () =>
    this.setState({
      requestChangeModalOpened: !this.state.requestChangeModalOpened
    });

  toggleValidateModal = () =>
    this.setState({ validateModalOpened: !this.state.validateModalOpened });

  render() {
    return (
      <AccessContext.Consumer>
        {({ id, status, updateStatus, readOnly }) => {
          return status === 1 && readOnly ? (
            <React.Fragment>
              <hr />
              <ButtonGroup>
                <Button color="danger" onClick={this.toggleRequestChangeModal}>
                  Request change
                </Button>
                <Button color="success" onClick={this.toggleValidateModal}>
                  Validate
                </Button>
              </ButtonGroup>
              <RequestChangeModal
                campaignPlanId={id}
                commentable={this.state.requestChangeModalOpened}
                toggleRequestChangeModal={this.toggleRequestChangeModal}
                updateStatus={updateStatus}
              />
              <ValidationModal
                campaignPlanId={id}
                validated={!this.state.validateModalOpened}
                toggleValidationModal={this.toggleValidateModal}
                updateStatus={updateStatus}
              />
            </React.Fragment>
          ) : null;
        }}
      </AccessContext.Consumer>
    );
  }
}

export default StatusBar;
