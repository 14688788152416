import { Button, Table } from 'reactstrap';

import AccessContext from '../contexes/AccessContext';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import React from 'react';
import RowHeaderCell from './RowHeaderCell';
import TagNameContext from '../contexes/TagNameContext';
import compact from 'lodash/compact';
import range from 'lodash/range';
import styled from 'styled-components';

const DataRow = styled.tr`
  th {
    padding: 0.5rem 1rem;
  }
  ${({ totalLine }) =>
    totalLine &&
    `
    font-weight: bold;
    td {
      background-color: rgba(0, 0, 0, 0.03);
    }
  `};
`;

const TableActionsBar = styled.div`
  text-align: right;
  margin-bottom: 1em;
`;

const TableXs = styled(Table)`
  font-size: 0.75rem;
`;

const HeaderRow = styled.tr`
  background-color: #6e757c;
  color: #fff;
  white-space: nowrap;
`;

const RawTable = ({ mediaPlan }) => {
  const header = mediaPlan.input[0];
  const headerLength = header.length;
  let emptyLineFound = false;

  return (
    <div>
      <AccessContext.Consumer>
        {({ readOnly }) =>
          !readOnly && (
            <TagNameContext.Consumer>
              {(inputs) =>
                inputs &&
                inputs.file &&
                inputs.file.value && (
                  <TableActionsBar>
                    <Button outline href={inputs.file.value} target="_blank">
                      <FontAwesomeIcon icon="download" /> Download media plan
                    </Button>
                  </TableActionsBar>
                )
              }
            </TagNameContext.Consumer>
          )
        }
      </AccessContext.Consumer>
      <TableXs responsive borderless hover>
        <thead>
          <HeaderRow>
            {header.map((h, index) => (
              <th key={index}>{h}</th>
            ))}
          </HeaderRow>
        </thead>
        <tbody>
          {mediaPlan.input.map((row, rowIndex) => {
            if (rowIndex === 0 || emptyLineFound) return null;
            emptyLineFound = compact(row).length === 0;
            return emptyLineFound ? null : (
              <DataRow key={rowIndex} totalLine={!row[0]}>
                {range(headerLength).map((index) => (
                  <RowHeaderCell key={index} className={index > mediaPlan.flightEndIndex ? 'text-right' : 'text-left'}>
                    {row[index] || null}
                  </RowHeaderCell>
                ))}
              </DataRow>
            );
          })}
        </tbody>
      </TableXs>
    </div>
  );
};

export default RawTable;
