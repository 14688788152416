import { FormGroup, FormText, Input, Label } from 'reactstrap';

import React from 'react';

const FieldGroup = ({ id, label, help, state, ...props }) => {
  props.invalid = !!state;

  return (
    <FormGroup>
      <Label>{label}</Label>
      <Input {...props} />
      {help && <FormText color="muted">{help}</FormText>}
    </FormGroup>
  );
};

export default FieldGroup;
