import { ButtonGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import SeeAudienceModel from './SeeAudienceModel';
import TagNameContext from '../../contexes/TagNameContext';

const AudienceLinks = ({ audience, index }) => (
  <ButtonGroup style={{ paddingBottom: '1rem' }}>
    <SeeAudienceModel audience={audience} />
    <TagNameContext.Consumer>
      {(inputs) =>
        inputs &&
        inputs.campaign_plan_tactics[index] &&
        inputs.campaign_plan_tactics[index].targetAudience
          .audienceAnalysisUrl && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-outline-secondary"
            href={
              inputs.campaign_plan_tactics[index].targetAudience
                .audienceAnalysisUrl
            }
          >
            Analysis
          </a>
        )
      }
    </TagNameContext.Consumer>
  </ButtonGroup>
);

AudienceLinks.propTypes = {
  audience: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default AudienceLinks;
