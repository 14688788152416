import { AlphaNumber } from 'happy-react-lib';
import { Button } from 'reactstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

const FileDescription = ({ file }) => {
  if (!file.url) {
    return null;
  }

  return (
    <Button outline href={file.url} target="_blank" title={file.name}>
      <FontAwesomeIcon icon="download" /> Download media plan (
      <AlphaNumber value={file.size} />)
    </Button>
  );
};

FileDescription.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    url: PropTypes.string,
  }).isRequired,
};

export default FileDescription;
