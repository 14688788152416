import React, { useState } from 'react';

import TacticNotes from '../../components/TacticNotes';

export default function TacticNotesPreview() {
  const [content, setContent] = useState('Edit this content');

  const props = {
    notes: [
      {
        name: 'Rationales',
        content: 'Some content for rationales',
      },
      {
        name: 'Tab number 2',
        content,
        onChange: setContent,
      },
    ],
  };

  return <TacticNotes {...props} />;
}
