import { Card, CardBody, CardHeader } from 'reactstrap';
import { toOption, toSelectOptions } from '../../utils/audiences';

import BaseAudience from './BaseAudience';
import EditOnly from '../EditOnly';
import PropTypes from 'prop-types';
import React from 'react';
import TabsNav from '../TabsNav';
import TacticAnalysis from './TacticAnalysis';
import TagNameContext from '../../contexes/TagNameContext';
import loadAudiences from '../../queries/audiences';

class PlanOverview extends React.Component {
  static propTypes = {
    mediaPlan: PropTypes.object.isRequired,
    initialBaseAudienceId: PropTypes.number,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      audiences: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  state = {
    baseAudience: null,
  };

  componentWillReceiveProps(newProps) {
    // if newProps give audiences back from GraphQL
    // and we have an initialBaseAudienceId through oldProps
    // AND base audience is still null in state
    // initialize it
    const { initialBaseAudienceId } = this.props;
    const { data } = newProps;

    if (initialBaseAudienceId && data.audiences && this.state.baseAudience === null) {
      const baseAudience = data.audiences.find((a) => a.id === initialBaseAudienceId);

      this.setState({ baseAudience });
    }
  }

  formatTactic = (roots) => {
    const { mediaPlan } = this.props;
    const totalBudget = mediaPlan.getTotalBudget() || 1;

    return roots.map((root, index) => {
      const investment = mediaPlan.getBudget(root);
      const ceof = mediaPlan.getCEOF(root);
      const totalOptimalFrequency = mediaPlan.getTotalOptimalFrequency(root);

      return {
        id: index + 1,
        name: root.label,
        label: root.label,
        prefix: `Tactic #${index + 1}`,
        investment,
        ceof,
        budgetSplit: investment / totalBudget,
        section: root,
        totalOptimalFrequency,
      };
    });
  };

  setCurrentTactic = (tactic) => {
    this.setState({ currentTactic: tactic });
  };

  audienceSelected = (option) => {
    const baseAudience = option ? this.props.data.audiences.find((a) => a.id === option.value) : null;

    this.setState({ baseAudience });
  };

  render() {
    const { data } = this.props;
    if (data.loading) {
      return null;
    }
    const audiences = toSelectOptions(data.audiences);
    const { mediaPlan } = this.props;
    const tactics = this.formatTactic(mediaPlan.getRoots());
    const activeTactic = this.state.currentTactic || tactics[0];

    return (
      <React.Fragment>
        <EditOnly>
          <BaseAudience
            audiences={audiences}
            baseAudience={this.state.baseAudience && toOption(this.state.baseAudience)}
            audienceSelected={this.audienceSelected}
          />
        </EditOnly>
        <Card>
          <CardHeader>Campaign strategy by tactics</CardHeader>
          <CardBody>
            <TabsNav activeItem={activeTactic} items={tactics} onClick={this.setCurrentTactic} />
            <TagNameContext.Consumer>
              {(inputs) => (
                <React.Fragment>
                  {tactics.map((tactic) => (
                    <div
                      className="mt-5"
                      key={tactic.id}
                      style={{
                        display: tactic.id === activeTactic.id ? 'block' : 'none',
                      }}
                    >
                      <TacticAnalysis
                        index={tactic.id - 1}
                        {...tactic}
                        mediaPlan={mediaPlan}
                        baseAudience={this.state.baseAudience}
                        initialTargetAudience={
                          inputs &&
                          inputs.campaign_plan_tactics &&
                          inputs.campaign_plan_tactics[tactic.id - 1] &&
                          data.audiences.find(
                            (a) => a.id === inputs.campaign_plan_tactics[tactic.id - 1].targetAudience.value
                          )
                        }
                        touchpoints={mediaPlan.getChildrenByType(tactic.section, 'Touchpoint')}
                      />
                    </div>
                  ))}
                </React.Fragment>
              )}
            </TagNameContext.Consumer>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default loadAudiences(PlanOverview);
