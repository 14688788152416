import BulletChart, { OVER_THRESHOLD_BACKGROUND, UNDER_THRESHOLD_BACKGROUND } from '../TouchpointCoverage/BulletChart';
import { Number, Percentage } from 'happy-react-lib';

import PropTypes from 'prop-types';
import React from 'react';

export default function DeliveryReach(props) {
  const { audienceName, audienceSize, actual, threshold, optimalFrequency } = props;
  const title = audienceName || '';
  const reachPercentage = actual / audienceSize;

  return (
    <div>
      <p>
        A <b>frequency of {optimalFrequency} across all media touchpoints</b> delivers a{' '}
        <b>{<Percentage value={reachPercentage} />} viewable reach on target</b> coverage of{' '}
        {<Number value={audienceSize} />} people audience.
      </p>
      <p className="mb-2">{title}</p>
      <BulletChart actual={actual} threshold={threshold} max={audienceSize} />
      <div className="d-flex justify-content-between mb-3">
        <span style={{ color: UNDER_THRESHOLD_BACKGROUND }}>Reach at optimal cost</span>
        <span style={{ color: OVER_THRESHOLD_BACKGROUND }}>Incremental reach</span>
      </div>
    </div>
  );
}

DeliveryReach.propTypes = {
  audienceName: PropTypes.string,
  audienceSize: PropTypes.number.isRequired,
  actual: PropTypes.number.isRequired,
  threshold: PropTypes.number.isRequired,
  optimalFrequency: PropTypes.number.isRequired,
};
