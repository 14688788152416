import { ColorsHelper } from 'happy-react-lib';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import concat from 'lodash/concat';
import flatten from 'lodash/flatten';
import { COLOR_PALETTE } from '../../utils/colors';

require('highcharts/modules/sankey')(Highcharts);

function linksHover(point, state) {
  if (point.isNode) {
    point.linksFrom.forEach(function (l) {
      l.setState(state);
    });
    point.linksTo.forEach(function (l) {
      l.setState(state);
    });
  }
}

class SankeyChart extends React.Component {
  structureLevel = (root) => {
    const { mediaPlan } = this.props;

    const children = this.props.mediaPlan.getChildren(root);

    if (children.length > 0) {
      const nodes = children.map((c) => [root.label, c.label, mediaPlan.getBudget(c)]);
      const subTree = children.map(this.structureLevel);

      return concat(nodes, flatten(subTree));
    }

    return [];
  };

  generateData = () => {
    const { mediaPlan } = this.props;

    const roots = mediaPlan.getRoots();

    return flatten(roots.map(this.structureLevel));
  };

  render() {
    const data = this.generateData();
    const currency = '$';
    const config = {
      title: {
        text: '',
      },
      chart: { height: '650px' },
      credits: { enabled: false },
      colors: COLOR_PALETTE,
      tooltip: {
        backgroundColor: 'rgba(255,255,255, 1)',
        useHTML: true,
        headerFormat: '',
        nodeFormat: '{point.name}: <b>{point.sum:,0f}</b><br/>',
        pointFormat: `{point.fromNode.name} → {point.toNode.name}: <b>${currency}{point.weight:,0f}</b><br/>`,
      },
      plotOptions: {
        sankey: {
          dataLabels: {
            enabled: false,
            useHTML: true, // to avoid any text shadow
            color: '#293113',
          },
          point: {
            events: {
              mouseOver: function () {
                linksHover(this, 'hover');
              },
              mouseOut: function () {
                linksHover(this, '');
              },
            },
          },
        },
      },
      series: [
        {
          keys: ['from', 'to', 'weight'],
          data: data,
          type: 'sankey',
          name: 'Investment',
        },
      ],
    };

    return <HighchartsReact highcharts={Highcharts} options={config} />;
  }
}

export default SankeyChart;
