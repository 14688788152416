import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PieChart } from 'happy-react-lib';
import React from 'react';
import { sortBy } from 'lodash';

const grayColors = [
  '#171717', // 900
  '#525252', // 600
  '#A3A3A3', // 400
  '#E5E5E5', // 200
  '#F5F5F5', // 100
  '#262626', // 800
  '#404040', // 700
  '#737373', // 500
  '#D4D4D4', // 300
  '#FAFAFA', // 50
];

class DimensionSplit extends React.Component {
  state = {
    dimension: '',
  };

  constructor(props) {
    super(props);

    if (this.props.dimensions && this.props.dimensions.length > 0) {
      this.state = {
        dimension: this.props.dimensions[0],
      };
    }
  }

  computeData = () => {
    const { nodes } = this.props;
    const budget = {};
    nodes.forEach((node) => {
      budget[node.label] = budget[node.label] || { color: node.color, value: 0 };
      const value = node[this.props.metricCallback].call(node);
      budget[node.label].value += value;
    });

    const data = Object.keys(budget).map((name) => ({
      name,
      y: budget[name].value,
      color: budget[name].color,
      dataLabels: {
        color: budget[name].color,
      },
    }));
    return sortBy(data, (d) => -d.y);
  };

  render() {
    const data = this.computeData();
    const config = {
      series: data,
      color: '#212529',
      name: this.props.metric,
      pointFormat: `<b>{point.y:,.0f} ${this.props.metric}</b>`,
      ChartGenericComponent: HighchartsProxy,
    };

    return (
      <section>
        <header>
          <h5>
            Distribution
            <br />({this.props.metric})
          </h5>
        </header>
        <PieChart {...config} />
      </section>
    );
  }
}

const HighchartsProxy = ({ config }) => <HighchartsReact options={config} highcharts={Highcharts} />;

export default DimensionSplit;
