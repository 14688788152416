import { Number } from 'happy-react-lib';
import React from 'react';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';

const AUDIENCE_SIZE_QUERY = gql`
  query q($baseId: Int!, $targetIds: [Int]!) {
    q(id: "q999", baseId: $baseId, audienceIds: $targetIds) {
      data {
        by_audience {
          audience {
            id
            name
          }
          weighted_universe_count
        }
      }
    }
  }
`;

class AudienceSize extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.data.loading !== prevProps.data.loading) {
      const { data } = this.props;

      if (data.q && data.q.data) {
        this.props.setAudienceSize(
          data.q.data[0].by_audience[0].weighted_universe_count
        );
      }
    }
  }

  render() {
    const { data } = this.props;

    if (data.loading) {
      return <p>Loading...</p>;
    }
    try {
      const size = data.q.data[0].by_audience[0].weighted_universe_count;
      const name = data.q.data[0].by_audience[0].audience.name;

      return (
        <p>
          The estimated size of "{name}" targeted is{' '}
          <b>
            <Number value={size} /> people
          </b>
        </p>
      );
    } catch (error) {
      console.log(error);
      return (
        <p className="alert alert-danger">
          Error evaluating audience size. Please contact your administrator.
        </p>
      );
    }
  }
}

export default graphql(AUDIENCE_SIZE_QUERY, {
  options: ({ baseAudience, audience }) => ({
    variables: {
      baseId: baseAudience.id,
      targetIds: [audience.id],
    },
  }),
})(AudienceSize);
