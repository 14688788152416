import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const TouchpointIcon = styled.img`
  width: ${({ small }) => (small ? '32px' : '48px')};
  height: ${({ small }) => (small ? '32px' : '48px')};
  ${({ active }) =>
    active === false && 'filter: invert(1) contrast(0.5); opacity: 0.5'};
`;

export const TouchpointLabel = styled.span`
  display: inline-block;
  font-size: 0.75em;
  text-align: center;
  ${({ active }) => active === false && 'opacity: 0.3'}
`;

const Touchpoint = ({
  touchpoint: { icon, label, description },
  active,
  small = false,
}) => (
  <div
    className="d-flex flex-column justify-content-between align-items-center"
    title={description ? description : `${label} touchpoint`}
  >
    <TouchpointIcon
      src={icon}
      alt={`${label} touchpoint`}
      active={active}
      small={small}
    />
    <TouchpointLabel active={active}>{label}</TouchpointLabel>
  </div>
);

Touchpoint.propTypes = {
  touchpoint: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default Touchpoint;
