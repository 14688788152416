import React, { useState } from 'react';

import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import SimpleModal from '../SimpleModal';

function MethodologyText({ modal = false, header = 'Methodology' }) {
  const [opened, setOpened] = useState(false);
  const toggle = () => setOpened(!opened);

  const Text = () => (
    <div>
      <p>We define the leading touchpoint(s) based on:</p>
      <ul>
        <li>Ability to deliver the relevant trigger</li>
        <li>Availability of creative assets</li>
        <li>Time to reach efficiency</li>
        <li>Investment to reach effectiveness</li>
        <li>Opportunities on target audience</li>
        <li>Affinity for target audience</li>
      </ul>
      <p>We consider different additional touchpoints in the mix to optimize cost and/or impact.</p>
      <p>
        We leverage the “% of overlapping opportunities” calculations to understand if we generate either incremental
        reach or frequency.
      </p>
      <p>We estimate the size the overlaps generated taking in consideration:</p>
      <ul>
        <li>Number of touchpoints in the mix</li>
        <li>Number of publishers at theses touchpoints</li>
        <li>Target audience size</li>
        <li>Pressure (reach & frequency) by touchpoint</li>
      </ul>
      <p>We calculate Optimization Score based on:</p>
      <ul>
        <li>Trading Agility: Flexibility of optimization </li>
        <li>Data Targeting: Scale at which we can use granular data in the buy</li>
        <li>Data Capture: What can I learn from this media? </li>
        <li>Creative Optimization: Impact of the creative</li>
      </ul>
    </div>
  );

  if (modal) {
    return (
      <>
        <Button outline onClick={toggle}>
          {header}
        </Button>
        <SimpleModal open={opened} toggle={toggle} header={header}>
          <Text />
        </SimpleModal>
      </>
    );
  } else {
    return <Text />;
  }
}

MethodologyText.propTypes = {
  modal: PropTypes.bool,
  header: PropTypes.string,
};

export default MethodologyText;
