import ChartTitle from '../ChartTitle';
import DropdownNav from '../DropdownNav';
import HeatMapChart from './Chart';
import React from 'react';
import flatten from 'lodash/flatten';
import numeral from 'numeraljs';
import touchpointLabelMatcher from '../../utils/touchpoint_label_matcher';
import uniq from 'lodash/uniq';

// Logic:
// For each yDimension
// gather each xDimension section
// compute the budget for it

class HeatMap extends React.Component {
  state = {
    xDimension: '',
    yDimension: '',
  };

  constructor(props) {
    super(props);
    const { mediaPlan } = props;
    this.dimensions = mediaPlan.getDimensions();
    if (this.dimensions && this.dimensions.length > 0) {
      this.state = {
        yDimension: props.yDimension || this.dimensions[0],
        xDimension:
          props.xDimension || this.dimensions[this.dimensions.length - 1],
      };
    }
  }

  // we use touchpointLabelMatcher in the comparaison as it's
  // a dimension that has changed since renaming
  // match will work for anything else
  computeData = (ySections, categories) => {
    const { mediaPlan } = this.props;
    const matrix = {};

    ySections.forEach((s) => {
      const children = mediaPlan.getChildrenByType(
        s,
        this.state.xDimension.label
      );
      const yIndex = categories.y.findIndex((l) => l === s.label);
      children.forEach((c) => {
        const xIndex = categories.x.findIndex((l) =>
          touchpointLabelMatcher(c.label, l)
        );
        const budget = mediaPlan.getBudget(c);

        matrix[xIndex] = matrix[xIndex] || {};
        matrix[xIndex][yIndex] = matrix[xIndex][yIndex] || 0;
        matrix[xIndex][yIndex] = matrix[xIndex][yIndex] + budget;
      });
    });

    return flatten(
      categories.x.map((_, xKey) =>
        categories.y.map((_, yKey) => [
          parseInt(xKey, 10),
          parseInt(yKey, 10),
          matrix[xKey] && matrix[xKey][yKey] ? matrix[xKey][yKey] : 0,
        ])
      )
    );
  };

  tooltipFormatter() {
    const xLabel = this.series.xAxis.categories[this.point.x];
    const yLabel = this.series.yAxis.categories[this.point.y];
    const amount = numeral(this.point.value).format('$0,0');

    return `<b>${amount}</b> invested in <b>${xLabel}</b> for <b>${yLabel}</b>.`;
  }

  setXDimension = (xDimension) => {
    this.setState({ xDimension });
  };

  setYDimension = (yDimension) => {
    this.setState({ yDimension });
  };

  render() {
    const { mediaPlan, onlyChart = false } = this.props;
    const xSections = mediaPlan.getStructureByType(this.state.xDimension.label);
    const xCategories =
      this.props.xCategories || uniq(xSections.map((s) => s.label));
    const ySections = mediaPlan.getStructureByType(this.state.yDimension.label);
    const yCategories = uniq(ySections.map((s) => s.label));
    const data = this.computeData(ySections, {
      y: yCategories,
      x: xCategories,
    });

    return (
      <React.Fragment>
        {!onlyChart && (
          <React.Fragment>
            <ChartTitle>Weight</ChartTitle>
            <div className="d-flex mb-3 align-items-center">
              <div className="w-25">
                <DropdownNav
                  activeItem={this.state.yDimension}
                  items={this.dimensions}
                  onClick={this.setYDimension}
                />
              </div>
              <span className="d-inline-block px-3">per</span>
              <div className="w-25">
                <DropdownNav
                  activeItem={this.state.xDimension}
                  items={this.dimensions}
                  onClick={this.setXDimension}
                />
              </div>
            </div>
          </React.Fragment>
        )}
        <HeatMapChart
          title=""
          data={data}
          xCategories={xCategories}
          yCategories={yCategories}
          tooltipFormatter={this.tooltipFormatter}
        />
      </React.Fragment>
    );
  }
}

export default HeatMap;
