import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import React from 'react';
require('highcharts/modules/bullet')(Highcharts);

export const UNDER_THRESHOLD_BACKGROUND = '#dff0d8';
export const OVER_THRESHOLD_BACKGROUND = '#f2dede';
const SERIE_COLOR = '#293113';

const BulletChart = ({ title, threshold, actual, max, yAxisTitle, serieName, pointFormat }) => {
  const config = {
    chart: {
      inverted: true,
      type: 'bullet',
      height: '110px',
      margin: 0,
    },
    title: { text: title },
    xAxis: {
      labels: { enabled: false },
      categories: [serieName],
    },
    yAxis: {
      gridLineWidth: 0,
      plotBands: [
        {
          from: 0,
          to: threshold,
          color: UNDER_THRESHOLD_BACKGROUND,
        },
        {
          from: threshold,
          to: 9e9,
          color: OVER_THRESHOLD_BACKGROUND,
        },
      ],
      max,
      title: {
        text: yAxisTitle,
      },
    },
    plotOptions: {
      series: {
        pointPadding: 0.25,
        borderWidth: 0,
        color: SERIE_COLOR,
        targetOptions: {
          width: '200%',
        },
      },
      bullet: {
        dataLabels: {
          enabled: true,
          format: '{percentage:,.2f}%',
          color: '#293113',
          style: {
            textOutline: null, // on this chart useHTML doesn't work. We need to set this option manually
          },
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        name: serieName,
        data: [
          {
            y: actual,
            target: threshold,
            targetOptions: {
              width: 0,
            },
            percentage: (100 * actual) / max,
          },
        ],
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={config} />;
};

BulletChart.propTypes = {
  title: PropTypes.string,
  actual: PropTypes.number.isRequired,
  threshold: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  yAxisTitle: PropTypes.string,
  serieName: PropTypes.string,
};

export default BulletChart;
