import AccessContext from '../../contexes/AccessContext';
import React from 'react';
import ScatterChart from '../ScatterChart';

function touchpointFormatter(currency) {
  return function () {
    return `<tr>
      <th colspan="2">
        <img width="18" src="${this.icon}" />
        ${this.name}
      </th>
    </tr>
    <tr>
      <th>CTCEOF:</th><td style='text-align: right'>${currency}${this.x}</td>
    </tr>
    <tr>
      <th>Optimization score:</th><td style="text-align: right">${this.y}</td>
    </tr>`;
  };
}

class TouchpointsChart extends React.Component {
  chartData = () => {
    const { touchpoints, selection } = this.props;

    return touchpoints.map((t) => {
      const isSelected = selection.findIndex((s) => s.id === t.id) > -1;

      return {
        name: t.label,
        icon: t.icon,
        marker: {
          symbol: `url(${isSelected ? t.iconUrl : t.negatedIconUrl})`,
        },
        x: t.defaultCpm * t.defaultOptimalFrequency,
        y:
          t.defaultCreativeOptimization +
          t.defaultDataCapture +
          t.defaultDataTargeting +
          t.defaultTradingAgility,
        touchpointId: t.id,
        color: isSelected ? '#000' : '#eee',
      };
    });
  };

  render() {
    return (
      <AccessContext.Consumer>
        {({ currency }) => (
          <ScatterChart
            xAxisTitle="Cost per Thousand Contacts Exposed with Optimal Frequency"
            yAxisTitle="Optimization score"
            xPreUnit={currency}
            xAxisReversed={true}
            pointFormatter={touchpointFormatter(currency)}
            data={this.chartData()}
          />
        )}
      </AccessContext.Consumer>
    );
  }
}

export default TouchpointsChart;
