import { TouchpointIcon, TouchpointLabel } from './Touchpoint';

import PropTypes from 'prop-types';
import React from 'react';
import SimpleModal from '../SimpleModal';

// based on a 710px height, macbook default.
const OPTIMAL_HEIGHT = 531;

const TouchpointsModal = ({ open, toggle, touchpoints, selection }) => {
  return (
    <SimpleModal
      open={open}
      toggle={toggle}
      header="Actionable touchpoints between people and brands"
      size="xl"
    >
      <div
        className="d-flex flex-column flex-wrap"
        style={{ height: OPTIMAL_HEIGHT }}
      >
        {touchpoints.map((t, index) => (
          <Touchpoint
            key={t.id}
            touchpoint={t}
            active={selection.findIndex((s) => s.id === t.id) > -1}
          />
        ))}
      </div>
    </SimpleModal>
  );
};

function Touchpoint({ touchpoint, active }) {
  const color = active ? '#212529' : '#a0a0a0';
  return (
    <div
      style={{ color }}
      className="p-1 mb-1 d-inline-flex align-items-center w-50"
    >
      <TouchpointIcon
        src={touchpoint.icon}
        alt={`${touchpoint.label} touchpoint`}
        active={active}
        small={false}
        style={{ margin: '0 .75em' }}
      />
      <div className="px-1">
        <TouchpointLabel style={{ textAlign: 'left', fontWeight: 'bold' }}>
          {touchpoint.label}
        </TouchpointLabel>
        {touchpoint.description && (
          <p style={{ fontSize: '0.75em', margin: 0 }}>
            {touchpoint.description}
          </p>
        )}
      </div>
    </div>
  );
}

TouchpointsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  touchpoints: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ),
  selection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
};

export default TouchpointsModal;
