import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import React from 'react';
import sortBy from 'lodash/sortBy';

const grayColors = [
  '#171717', // 900
  '#525252', // 600
  '#A3A3A3', // 400
  '#404040', // 700
  '#E5E5E5', // 200
  '#F5F5F5', // 100
  '#262626', // 800
  '#737373', // 500
  '#D4D4D4', // 300
  '#FAFAFA', // 50
];

export default function EstimatedContactsOnTarget({ data }) {
  if (data.length === 0) {
    return <p>Unable to match touchpoints</p>;
  }
  const aggregatedTouchpoints = data.reduce((d, touchpoint) => {
    d[touchpoint.name] = d[touchpoint.name] || {
      name: touchpoint.name,
      color: touchpoint.color,
      onTarget: 0,
      viewableContacts: 0,
    };
    d[touchpoint.name].onTarget += touchpoint.onTarget;
    d[touchpoint.name].viewableContacts += touchpoint.viewableContacts;

    return d;
  }, {});

  const sortedData = sortBy(Object.values(aggregatedTouchpoints), (d) => -d.onTarget);
  const categories = sortedData.map((d) => d.name);
  const chartData = sortedData.map((d, index) => ({
    ...d,
    y: d.onTarget,
    viewableContacts: d.viewableContacts,
    percentage: (d.onTarget * 100) / d.viewableContacts,
    dataLabels: {
      color: d.color,
    },
  }));

  return (
    <section>
      <header>
        <h5>
          On target delivery accuracy
          <br />
          (Viewable contacts)
        </h5>
      </header>
      <StackedChart
        data={chartData}
        categories={categories}
        title={null}
        yUnit=" contacts"
        yTitle=""
        dataLabelFormat="{point.percentage:,.0f}%"
      />
    </section>
  );
}

function StackedChart({ data, categories }) {
  const offTargetData = data.map((d, index) => ({
    ...d,
    y: d.viewableContacts - d.onTarget,
    color: `${d.color}50`,
    dataLabels: {
      color: `${d.dataLabels.color}`,
    },
  }));

  const options = {
    chart: {
      type: 'column',
    },
    title: null,
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    xAxis: {
      categories,
    },
    yAxis: {
      title: null,
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y:,.0f} viewable contacts</b><br/>',
    },
    series: [
      {
        name: 'On target',
        data,
        dataLabels: {
          enabled: true,
          format: '{point.percentage:,.0f}%',
          verticalAlign: 'top',
          y: -20,
        },
      },
      {
        name: 'Off target',
        data: offTargetData,
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

EstimatedContactsOnTarget.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      viewableContacts: PropTypes.number.isRequired,
      onTarget: PropTypes.number.isRequired,
    })
  ),
};
