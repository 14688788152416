import { ColorsHelper } from 'happy-react-lib';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { colorListAt, COLOR_PALETTE } from '../../utils/colors';

require('highcharts/modules/xrange')(Highcharts);

const CHART_MARGIN_TOP = 100;
const CHART_MARGIN_BOTTOM = 100;
const CHART_ROW_HEIGHT = 50;

class XRangeChart extends React.Component {
  render() {
    const config = {
      chart: {
        type: 'xrange',
        height: `${CHART_MARGIN_TOP + CHART_MARGIN_BOTTOM + this.props.categories.length * CHART_ROW_HEIGHT}px`,
      },
      colors: COLOR_PALETTE,
      title: {
        text: this.props.title,
      },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: { type: 'datetime' },
      yAxis: {
        title: { text: '' },
        categories: this.props.categories,
        gridLineColor: '#fff',
        labels: {
          useHTML: true,
          formatter: function () {
            const value = this.value;
            const color = colorListAt(this.pos);

            return `<p style='text-align: right; color: ${color}'>${value}</p>`;
          },
        },
        reversed: true,
      },
      tooltip: {
        formatter: this.props.tooltipFormatter,
        backgroundColor: 'rgba(255,255,255, 1)',
        useHTML: true,
      },
      series: [
        {
          name: this.props.dimension,
          pointWidth: 20,
          data: this.props.periods,
          dataLabels: { enabled: false },
        },
      ],
    };

    return <HighchartsReact highcharts={Highcharts} options={config} />;
  }
}

export default XRangeChart;
