import ChartTitle from '../ChartTitle';
import DropdownNav from '../DropdownNav';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PieChart } from 'happy-react-lib';
import PropTypes from 'prop-types';
import React from 'react';

class DimensionSplit extends React.Component {
  static propTypes = {
    mediaPlan: PropTypes.object.isRequired,
  };

  state = {
    dimension: '',
    metric: '',
  };

  constructor(props) {
    super(props);
    const { mediaPlan } = props;
    this.dimensions = mediaPlan.getDimensions();
    if (this.dimensions && this.dimensions.length > 0) {
      this.state.dimension = this.dimensions[0];
    }
    this.metrics = mediaPlan.getSummableMetrics();
    if (this.metrics && this.metrics.length > 0) {
      this.state.metric = this.metrics[0];
    }
  }

  computeData = () => {
    const { mediaPlan } = this.props;
    const nodes = mediaPlan.getStructureByType(this.state.dimension.label);
    const budget = {};
    nodes.forEach((node) => {
      budget[node.label] = budget[node.label] || 0;
      const value = mediaPlan.getAggregateMetric(this.state.metric.id)(node);
      budget[node.label] = budget[node.label] + value;
    });

    const data = Object.keys(budget).map((name) => ({
      name,
      y: budget[name],
    }));

    return data;
  };

  setDimension = (dimension) => this.setState({ dimension });
  setMetric = (metric) => this.setState({ metric });

  render() {
    const data = this.computeData();
    const config = {
      series: data,
      name: this.state.metric.label,
      ChartGenericComponent: HighchartsProxy,
    };

    return (
      <React.Fragment>
        <ChartTitle>Contribution</ChartTitle>
        <div className="d-flex mb-3 align-items-center">
          <div className="w-25">
            <DropdownNav activeItem={this.state.metric} items={this.metrics} onClick={this.setMetric} />
          </div>
          <span className="d-inline-block px-3">per</span>
          <div className="w-25">
            <DropdownNav activeItem={this.state.dimension} items={this.dimensions} onClick={this.setDimension} />
          </div>
        </div>

        <PieChart {...config} />
      </React.Fragment>
    );
  }
}
const HighchartsProxy = ({ config }) => <HighchartsReact options={config} highcharts={Highcharts} />;

export default DimensionSplit;
