import { OVER_THRESHOLD_BACKGROUND, UNDER_THRESHOLD_BACKGROUND } from '../TouchpointCoverage/BulletChart';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

export default function SaturationChart({ data, threshold }) {
  const options = {
    chart: {
      type: 'spline',
    },
    title: null,
    xAxis: {
      tickInterval: 1,
      title: {
        text: 'Frequency',
      },
      plotBands: [
        {
          // mark the weekend
          color: UNDER_THRESHOLD_BACKGROUND,
          from: 0,
          to: threshold + 0.5,
        },
        {
          // mark the weekend
          color: OVER_THRESHOLD_BACKGROUND,
          from: threshold + 0.5,
          to: data.length,
        },
      ],
    },
    yAxis: {
      max: 100,
      labels: {
        format: '{value:.0f}%',
      },
      title: { text: 'Reach' },
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y:.0f}% of the audience reached with a frequency of {point.x}',
    },
    series: [
      {
        name: 'Accumulation',
        data: data.map((d) => d * 100),
        color: '#293113',
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
