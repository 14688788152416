import { Col, Table as ReactstrapTable, Row } from 'reactstrap';
import { OVER_THRESHOLD_BACKGROUND, UNDER_THRESHOLD_BACKGROUND } from '../TouchpointCoverage/BulletChart';
import touchpointReach, {
  multitouchpointReach,
  touchpointExclusiveReach,
  touchpointOverlap,
} from './touchpointReachHelper';

import DistributionChart from './DistributionChart';
import ErrorBoundary from '../ErrorBoundary';
import OnTargetReachChart from './OnTargetReachChart';
import { Percentage } from 'happy-react-lib';
import PropTypes from 'prop-types';
import ReachAvailabilityChart from './ReachAvailabilityChart';
import React from 'react';
import SaturationChart from './SaturationChart';
import Venn from './Venn';
import contactDistribution from './contactDistributionHelper';
import contactSaturation from './contactSaturationHelper';
import styled from 'styled-components';

const Table = styled(ReactstrapTable)`
  font-size: 0.75em;
`;

const Tr = styled.tr`
  background-color: #6e757c;
  color: #fff;
  white-space: nowrap;
`;

const Tfoot = styled.tfoot`
  tr {
    background-color: #f7f7f7;
    border-top: 1px solid #6e757c;
  }
`;

export default function MultiTouchpointsReach({ reach }) {
  return (
    <section>
      <ExclusiveReach reach={reach} />
      <ContactDistribution reach={reach} />
      <Synthesis reach={reach} />
      <TouchpointSynthesis reach={reach} />
      <TouchpointOverlap reach={reach} />
    </section>
  );
}

MultiTouchpointsReach.propTypes = {
  reach: PropTypes.arrayOf(
    PropTypes.shape({
      touchpoint: PropTypes.string.isRequired,
      publisher: PropTypes.string.isRequired,
      reach: PropTypes.number.isRequired,
    })
  ),
};

function Synthesis({ reach }) {
  return (
    <Table responsive borderless hover>
      <thead>
        <Tr>
          <th>Touchpoint</th>
          <th>Publisher</th>
          <th>Reach per publisher</th>
        </Tr>
      </thead>
      <tbody>{reach && reach.map((data, index) => <Reach key={index} {...data} />)}</tbody>
    </Table>
  );
}

function Reach({ touchpoint, publisher, reach }) {
  return (
    <tr>
      <td>{touchpoint}</td>
      <td>{publisher}</td>
      <td className="text-right">
        <Percentage value={reach} />
      </td>
    </tr>
  );
}

function TouchpointSynthesis({ reach }) {
  const data = touchpointReach(reach);
  const overall = multitouchpointReach(data);

  return (
    <Table responsive borderless hover>
      <thead>
        <Tr>
          <th>Touchpoint</th>
          <th>Reach per touchpoint</th>
          <th>% persons not reached in a specific touchpoint</th>
        </Tr>
      </thead>
      <tbody>{data && data.map((data, index) => <TouchpointReach key={index} {...data} />)}</tbody>
      <tfoot>
        <tr>
          <td>Multitouchpoint Reach</td>
          <td className="text-right">
            <Percentage value={overall} />
          </td>
          <td>&nbsp;</td>
        </tr>
      </tfoot>
    </Table>
  );
}

function TouchpointReach({ touchpoint, reach }) {
  return (
    <tr>
      <td>{touchpoint}</td>
      <td className="text-right">
        <Percentage value={reach} />
      </td>
      <td className="text-right">
        <Percentage value={1 - reach} />
      </td>
    </tr>
  );
}

// Apply a minimum relevant size ( in percentage )
// for an intersection to be displayed in the chart.
const AUDIENCE_MIN_PERCENTAGE = 0.01;

export function TouchpointOverlap({ audienceSize, reach }) {
  const valueThreshold = AUDIENCE_MIN_PERCENTAGE * audienceSize;
  const data = touchpointOverlap(reach);
  const touchpoints = touchpointReach(reach);
  const vennData = data
    .map((d) => ({
      sets: d.touchpoints,
      value: d.overlap * audienceSize,
    }))
    .concat(
      touchpoints.map((t) => {
        const rt = reach.find((rt) => rt.label === t.touchpoint) || {};

        return {
          sets: [t.touchpoint],
          color: rt.color,
          value: rt.peopleReached,
        };
      })
    )
    .filter((d) => d.value > valueThreshold);

  return (
    <article>
      {/* <Table responsive borderless hover>
        <thead>
          <Tr>
            <th>Touchpoint combination</th>
            <th>Overlap</th>
          </Tr>
        </thead>
        <tbody>
          {data &&
            data.map((d, index) => (
              <tr key={index}>
                <td>{d.touchpoints.join(' - ')}</td>
                <td className="text-right">
                  <Percentage value={d.overlap} />
                </td>
              </tr>
            ))}
        </tbody>
      </Table> */}
      <ErrorBoundary data={vennData}>
        <Venn data={vennData} />
      </ErrorBoundary>
    </article>
  );
}

export function ExclusiveReach({ reach }) {
  const data = touchpointExclusiveReach(reach);
  return (
    <article>
      {/* <Table responsive borderless hover>
        <thead>
          <Tr>
            <th>Touchpoint</th>
            <th>Frequency / Shared reach</th>
            <th>Penetration / Exclusive reach</th>
          </Tr>
        </thead>
        <tbody>
          {data &&
            data.map((d, index) => (
              <tr key={index}>
                <td>{d.touchpoint}</td>
                <td className="text-right">
                  <Percentage value={d.sharedReach} />
                </td>
                <td className="text-right">
                  <Percentage value={d.exclusiveReach} />
                </td>
              </tr>
            ))}
        </tbody>
      </Table> */}
      <OnTargetReachChart data={data} />
    </article>
  );
}

// const N = 339.0;
const N = 252;
// const TRUE_TARGET_RATING_POINTS = 902;
const TRUE_TARGET_RATING_POINTS = 1891;
export function ContactDistribution({ sumOfOptimalFrequency = N, trueTargetRatingPoints = TRUE_TARGET_RATING_POINTS }) {
  const p = trueTargetRatingPoints / sumOfOptimalFrequency / 100;
  const distribution = [0].concat(contactDistribution(sumOfOptimalFrequency, p));
  const maxDistribution = Math.max(...distribution);
  const inflectionPoint = distribution.indexOf(maxDistribution);

  return (
    <article>
      {/* <Table>
        <thead>
          <Tr>
            <th>p(S=k)</th>
            <th title="We will have this % of people reached at optimal frequency">Contact Distribution</th>
          </Tr>
        </thead>
        <tbody>
          {distribution &&
            distribution.map((d, index) => (
              <tr key={index}>
                <td>{index}</td>
                <td>
                  <Percentage value={d} />
                </td>
              </tr>
            ))}
        </tbody>
      </Table> */}
      <Row>
        <Col>
          <header>
            <h5>Reach/Frequency accumulation</h5>
          </header>
          <div className="d-flex justify-content-between px-2">
            <span style={{ color: UNDER_THRESHOLD_BACKGROUND }}>Reach at optimal cost</span>
            <span style={{ color: OVER_THRESHOLD_BACKGROUND }}>Incremental reach</span>
          </div>

          <SaturationChart data={contactSaturation(distribution)} threshold={inflectionPoint} />
        </Col>
      </Row>
      <Row>
        <Col>
          <header>
            <h5>Reach/Frequency distribution</h5>
          </header>

          <DistributionChart data={distribution} />
        </Col>
        <Col>
          <header>
            <h5>Coverage/Frequency accumulation</h5>
          </header>
          <ReachAvailabilityChart data={distribution} />
        </Col>
      </Row>
    </article>
  );
}
