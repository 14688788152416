import AccessContext from '../contexes/AccessContext';
import React from 'react';

const ReadOnly = ({ children }) => {
  return (
    <AccessContext.Consumer>
      {({ readOnly }) =>
        readOnly && <React.Fragment>{children}</React.Fragment>
      }
    </AccessContext.Consumer>
  );
};

export default ReadOnly;
