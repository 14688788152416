import { Alert, Card, CardBody, CardHeader, CardText, CardTitle } from 'reactstrap';
import React, { useState } from 'react';

import AmountWithCurrency from './Amount';
import DetailedPlan from './DetailedPlan';
import FlightDates from './FlightDates';
import HeatMap from './HeatMap';
import PlanOverview from './PlanOverview';
import PropTypes from 'prop-types';
import Section from './Section';
import TacticCosts from './TacticCosts';
import TagNameContext from '../contexes/TagNameContext';
import loadTouchpoints from '../queries/touchpoints';
import max from 'lodash/max';
import uniq from 'lodash/uniq';

class TouchpointsPlanning extends React.Component {
  static propTypes = {
    mediaPlan: PropTypes.object.isRequired,
  };

  state = {
    baseAudience: null,
  };

  render() {
    const { mediaPlan, country } = this.props;
    const tactics = mediaPlan.getRoots();
    const flightDates = mediaPlan.getFlightDates();

    return (
      <React.Fragment>
        <Section>
          <OverviewWithTouchpoints
            mediaPlan={mediaPlan}
            tacticsCount={tactics.length}
            country={country}
            flightDates={flightDates}
          />
        </Section>
        <Section>
          <TagNameContext.Consumer>
            {(inputs) => (
              <PlanOverview
                mediaPlan={mediaPlan}
                initialBaseAudienceId={inputs && inputs.baseAudience && inputs.baseAudience.value}
              />
            )}
          </TagNameContext.Consumer>
        </Section>
        <Section>
          <FlightDates mediaPlan={mediaPlan} />
        </Section>
        <Section>
          <DetailedPlan mediaPlan={mediaPlan} />
        </Section>
      </React.Fragment>
    );
  }
}

const OverviewWithTouchpoints = loadTouchpoints(Overview);

function Overview({ data, mediaPlan, tacticsCount, country, flightDates }) {
  if (data.loading) {
    return 'Loading, please wait...';
  }
  if (data.touchpoints == null) {
    return (
      <Alert color="danger">An error occurred while loading touchpoints... Please contact an administrator.</Alert>
    );
  }
  const totalBudget = mediaPlan.getTotalBudget();
  const selectedTouchpointsCount = uniq(
    mediaPlan.getChildrenByType({ id: null }, 'Touchpoint').map((t) => t.label)
  ).length;
  const dimensions = mediaPlan.getDimensions();
  const touchpointDimension = dimensions.find((d) => d.label === 'Touchpoint');

  return (
    <Card>
      <CardHeader>Campaign overview</CardHeader>
      <CardBody>
        <CardText>
          <p>
            The campaign is scheduled to run in <b>{country}</b> from <b>{flightDates[0]}</b> to <b>{flightDates[1]}</b>
            .
          </p>
          <p>
            The total campaign investment is{' '}
            <b>
              <AmountWithCurrency value={totalBudget} />
            </b>{' '}
            {tacticsCount > 1 && (
              <span>
                split between <b>{tacticsCount} tactics</b>
              </span>
            )}{' '}
            {selectedTouchpointsCount > 1 ? 'across' : 'on'}{' '}
            <b>
              {selectedTouchpointsCount} touchpoint
              {selectedTouchpointsCount > 1 && 's'}
            </b>
            .
          </p>
        </CardText>
        <div className="mb-3">
          <HeatMap
            mediaPlan={mediaPlan}
            onlyChart={true}
            yDimension={dimensions[0]}
            xCategories={data.touchpoints.map((t) => t.label)}
            xDimension={touchpointDimension}
          />
        </div>
        <TacticCostsWrapper
          mediaPlan={mediaPlan}
          dimensions={dimensions.map((dimension) => ({
            value: dimension.id,
            label: dimension.label,
          }))}
          tactic={{ id: null }}
        />
      </CardBody>
    </Card>
  );
}

function TacticCostsWrapper({ mediaPlan, tactic, dimensions }) {
  const potentialTouchpointIndex = dimensions.findIndex((d) => d.label === 'Touchpoint');
  const [activeDimension, setActiveDimension] = useState(dimensions[max([potentialTouchpointIndex, 0])]);
  const metrics = mediaPlan
    .getChildrenByType(tactic, activeDimension.label)
    .map((child) => ({
      label: child.label,
      budget: child.getBudget(),
      contacts: child.getContacts(),
      clicks: child.getClicks(),
      conversions: child.getConversions(),
    }))
    .reduce((acc, row) => {
      acc[row.label] = acc[row.label] || {
        label: row.label,
        budget: 0,
        contacts: 0,
        clicks: 0,
        conversions: 0,
      };
      acc[row.label].budget = acc[row.label].budget + row.budget;
      acc[row.label].contacts = acc[row.label].contacts + row.contacts;
      acc[row.label].clicks = acc[row.label].clicks + row.clicks;
      acc[row.label].conversions = acc[row.label].conversions + row.conversions;

      return acc;
    }, {});

  return (
    <Section last={true}>
      <TacticCosts
        dimensions={dimensions}
        metrics={Object.values(metrics)}
        activeDimension={activeDimension}
        callback={setActiveDimension}
      />
    </Section>
  );
}

export default TouchpointsPlanning;
