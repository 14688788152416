import React, { useContext, useEffect, useRef } from 'react';

import { Button } from 'reactstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Parser from './utils/Parser';
import PropTypes from 'prop-types';
import TagNameContext from './contexes/TagNameContext';
import XLSX from 'xlsx';
import styled from 'styled-components';

const FileButton = styled(Button)`
  position: relative;
  overflow: hidden;

  > input[type='file'] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
  }
`;

function FileSelector({ selectMediaPlan, onFileSelect }) {
  const fileInputRef = useRef(null);
  // TODO: once a file is selected ( through reload ) display filename on full line
  const inputs = useContext(TagNameContext);

  useEffect(() => {
    if (!window.FileReader) {
      alert('FileReader not supported by your browser');
    }
  }, []);

  const handleFile = ({ target }) => {
    const files = target.files;
    if (files.length !== 1) {
      alert('Please select one file');
      return;
    }

    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      selectMediaPlan(parseMediaPlan(e.target.result));
    };

    if (onFileSelect) {
      onFileSelect(file);
      // remove the id so backend creates a new instance
      const fileIdName = inputs?.file?.idName;
      if (fileIdName) {
        $(`input[name='${fileIdName}']`).val("")
      }
    }
    reader.readAsArrayBuffer(file);
  };

  return (
    <FileButton outline>
      <FontAwesomeIcon icon="upload" /> Import new plan
      <input
        ref={fileInputRef}
        type="file"
        id="file"
        name={inputs && inputs.file ? inputs.file.tagName : null}
        onChange={handleFile}
        accept=".xlsx"
      />
    </FileButton>
  );
}

function parseMediaPlan(content) {
  const data = new Uint8Array(content);
  const workbook = XLSX.read(data, { type: 'array' });
  const firstSheetName = workbook.SheetNames[0];
  const firstSheet = workbook.Sheets[firstSheetName];
  const input = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

  return new Parser(input);
}

FileSelector.propTypes = {
  selectMediaPlan: PropTypes.func.isRequired,
  onFileSelect: PropTypes.func,
};

export default FileSelector;
