import Chart from './Chart';
import ChartTitle from '../ChartTitle';
import Dimensions from './Dimensions';
import React from 'react';

class Sankey extends React.Component {
  render() {
    const { mediaPlan } = this.props;

    return (
      <React.Fragment>
        <ChartTitle>Investment flow</ChartTitle>
        <Dimensions dimensions={mediaPlan.getDimensions()} />
        <Chart mediaPlan={mediaPlan} />
      </React.Fragment>
    );
  }
}

export default Sankey;
