import MultiTouchpointsReach from '../../components/MultiTouchpointsReach';
import React from 'react';

export default function MultiTouchpointsReachPreview() {
  const props = {
    reach: [
      {
        touchpoint: 'TV',
        publisher: 'TV',
        reach: 0.75,
      },
      {
        touchpoint: 'Social',
        publisher: 'Facebook',
        reach: 0.70842,
      },
      {
        touchpoint: 'Social',
        publisher: 'Instagram',
        reach: 0.3411,
      },
      {
        touchpoint: 'Social',
        publisher: 'Twitter',
        reach: 0.1894,
      },
      {
        touchpoint: 'Display in-web',
        publisher: 'DV360',
        reach: 0.01804,
      },
      {
        touchpoint: 'Video in-stream',
        publisher: 'YouTube',
        reach: 0.50525,
      },
    ],
  };

  return <MultiTouchpointsReach {...props} />;
}
