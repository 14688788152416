import { Navbar, NavbarBrand } from 'reactstrap';

import Favicon from 'react-favicon';
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

const StyledNavBar = styled(Navbar)`
  height: 50px;
  line-height: 50px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e7e7e7;
  padding: 0 8px;
  position: fixed;
  width: 100%;
  z-index: 3;
`;

const StyledNavbarBrand = styled(NavbarBrand)`
  font-size: 1rem;
`;

const StyledLogo = styled.img`
  display: inline-block;
  width: 24px;
  margin: 5px;
`;

const CustomNavbar = ({ color = '#f00', icon }) => (
  <StyledNavBar color="light" light expand="md">
    <Favicon url={icon} />
    <StyledNavbarBrand>
      <StyledLogo src={icon} alt="Logo" />
      <span style={{ color }}>happy</span>
      {moment()
        .format('dddd')
        .toLowerCase()}
    </StyledNavbarBrand>
  </StyledNavBar>
);

export default CustomNavbar;
