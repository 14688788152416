import './App.css';

import { ButtonGroup, Col, Container, Row } from 'reactstrap';
import React, { Component } from 'react';

import AccessContext from './contexes/AccessContext';
import CampaignPlanSharedHeader from './components/CampaignPlanSharedHeader';
import CustomNavbar from './components/CustomNavbar';
import FileDescription from './components/FileDescription';
import FileSelector from './FileSelector';
import Footer from './components/Footer';
import Parser from './utils/Parser';
import PlanningHeader from './components/PlanningHeader';
import Section from './components/Section';
import StatusBar from './components/StatusBar';
import TagNameContext from './contexes/TagNameContext';
import TouchpointsPlanning from './components/TouchpointsPlanning';
import XLSX from 'xlsx';
import styled from 'styled-components';

const ContainerWrapper = styled(Container)`
  padding-top: ${({ readOnly }) => (readOnly ? '75px' : '0px')};
`;

class App extends Component {
  state = {
    mediaPlan: null,
    selectedFile: null,
    status: 0,
  };

  constructor(props) {
    super(props);

    if (props.initialState) {
      if (props.initialState.planAsRaw) {
        const data = props.initialState.planAsRaw;
        const workbook = XLSX.read(data);
        const firstSheetName = workbook.SheetNames[0];
        const firstSheet = workbook.Sheets[firstSheetName];
        const input = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
        this.state.mediaPlan = new Parser(input);
      }

      if (props.initialState.file) {
        const size = parseInt(props.initialState.file.size, 0);
        if (size > 0) {
          this.state.selectedFile = {
            size,
            name: props.initialState.file.name,
            url: props.initialState.file.url,
          };
        }
      }

      if (props.initialState.team) {
        this.team = props.initialState.team;
      }

      if (props.initialState.status) {
        this.state.status = props.initialState.status;
      }
    }
  }

  setMediaPlan = (mediaPlan) => this.setState({ mediaPlan });
  setSelectedFile = (selectedFile) => this.setState({ selectedFile });
  updateStatus = (status) => this.setState({ status });

  render() {
    const { mediaPlan } = this.state;
    const {
      readOnly,
      currency,
      id,
      status,
      updateStatus,
      slug,
      name,
      client,
      clientPatterns,
      brand,
      country,
      updatedAt,
    } = this.props.initialState || { readOnly: false, currency: '$' };
    const accessContextValue = {
      readOnly,
      currency,
      id,
      status,
      updateStatus,
      slug,
      name,
      client,
      clientPatterns,
      brand,
      country,
      updatedAt,
    };
    const tagNameContextValue = this.props.initialState && this.props.initialState.inputs;

    return (
      <AccessContext.Provider value={accessContextValue}>
        <TagNameContext.Provider value={tagNameContextValue}>
          {this.team && <CustomNavbar icon={this.team.icon} color={this.team.color || '#f00'} />}
          <ContainerWrapper readOnly={accessContextValue.readOnly}>
            <Row>
              <Col md={accessContextValue.readOnly ? 12 : 6}>
                <PlanningHeader
                  brand={accessContextValue.brand}
                  name={accessContextValue.name}
                  color={(this.team && this.team.color) || '#212529'}
                />
              </Col>
              {!accessContextValue.readOnly && (
                <Col md={6} className="d-flex align-items-start justify-content-end mb-5">
                  <ButtonGroup>
                    {this.state.selectedFile && <FileDescription file={this.state.selectedFile} />}
                    <FileSelector selectMediaPlan={this.setMediaPlan} onFileSelect={this.setSelectedFile} />
                  </ButtonGroup>
                </Col>
              )}
            </Row>
            {accessContextValue.readOnly && <CampaignPlanSharedHeader {...accessContextValue} mediaPlan={mediaPlan} />}
            {mediaPlan && (
              <Section>
                <TouchpointsPlanning mediaPlan={mediaPlan} country={accessContextValue.country} />
                <StatusBar />
              </Section>
            )}
          </ContainerWrapper>
          {accessContextValue.readOnly && <Footer />}
        </TagNameContext.Provider>
      </AccessContext.Provider>
    );
  }
}

export default App;
