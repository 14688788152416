import AudienceModel from './AudienceModel';
import { Button } from 'reactstrap';
import { ColorsHelper } from 'happy-react-lib';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleModal from '../SimpleModal';
import styled from 'styled-components';

const AudienceName = styled.span`
  color: ${({ index }) => ColorsHelper.colorAt(index)};
`;

class SeeAudienceModel extends React.Component {
  static propTypes = {
    audience: PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  };

  state = {
    modalOpen: false
  };

  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen });

  render() {
    const { audience } = this.props;

    const header = <AudienceName index={0}>{audience.name}</AudienceName>;
    return (
      <React.Fragment>
        <Button outline onClick={this.toggleModal}>
          Model
        </Button>
        <SimpleModal
          header={header}
          toggle={this.toggleModal}
          open={this.state.modalOpen}
        >
          <AudienceModel audience={audience} color={ColorsHelper.colorAt(0)} />
        </SimpleModal>
      </React.Fragment>
    );
  }
}

export default SeeAudienceModel;
