import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.footer`
  margin-top: 1em;
  padding: 1em;
  border-top: 1px solid #e7e7e7;
  color: #777;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  a {
    color: var(--red-color);
  }
`;

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Wrapper>
      <p>
        &copy; {year} ISPD Group (
        <a href="https://ispd.com" target="_blank" rel="noopener noreferrer">
          ispd.com
        </a>
        )
      </p>
      <p>
        How can we make you happier?
        <br />
        Send us feedback at{' '}
        <a href="mailto:hello@ispd.com" target="_blank" rel="noopener noreferrer">
        hello@ispd.com
        </a>
      </p>
    </Wrapper>
  );
};

export default Footer;
