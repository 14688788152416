import { REVIEW_NEEDED, VALIDATED, VALIDATION_PENDING } from '../constants';
import React, { useState } from 'react';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Blockquote = styled.blockquote`
  border-left: 5px solid #f00;
  font-size: 14px;
  padding: 10px 20px;
  margin: 0 0 20px;
`;

const Link = styled.a`
  color: #f12915;
  &:visited {
    color: #f12915;
  }
`;

function nextAction({ status, brand, client }) {
  switch (status) {
    case REVIEW_NEEDED:
      return 'We are reviewing your feedback. No specific action is required.';
    case VALIDATION_PENDING:
      return `You are invited to review and validate this campaign plan.`;
    case VALIDATED:
      return 'This campaign plan has been validated. Thank you.';

    default:
      throw new Error(`Invalid status received: ${status}`);
  }
}

const CampaignPlanSharedHeader = ({ brand, client, status, updatedAt }) => {
  const humanDate = new Date(updatedAt).toLocaleString();

  return (
    <Blockquote>
      <p className="mb-0">{nextAction({ status, brand, client })}</p>
      <p className="text-muted mb-0">Last modification: {humanDate}</p>
      <p className="mb-0">
        <Link
          className="d-inline mr-1"
          href={document.location.href}
          target="_blank"
        >
          {document.location.href}
        </Link>
        <Copy value={document.location.href} />
      </p>
    </Blockquote>
  );
};

const Clickable = styled.span`
  cursor: pointer;
`;

function Copy({ value }) {
  const [copied, setCopied] = useState(false);
  const copy = () => {
    navigator.clipboard.writeText(value).then(() => {
      setCopied(true);
    });
  };

  return (
    <Clickable>
      <FontAwesomeIcon icon="copy" onClick={copy} />
      {copied && ' Copied!'}
    </Clickable>
  );
}

CampaignPlanSharedHeader.propTypes = {
  brand: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  updatedAt: PropTypes.string.isRequired,
};

export default CampaignPlanSharedHeader;
