import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';

import DimensionSplit from './TouchpointCoverage/DimensionSplit';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import HeatMap from './HeatMap';
import PropTypes from 'prop-types';
import RawTable from './RawTable';
import React from 'react';
import SankeyChart from './Sankey';
import Sunburst from './Sunburst';

const TABLE_INDEX = 1;
const DIMENSION_SPLIT_INDEX = 2;
const SANKEY_INDEX = 3;
const SUNBURST_INDEX = 4;
const HEATMAP_INDEX = 5;

const DetailedPlanNav = ({ activeIndex, callback }) => (
  <ButtonGroup>
    <Button outline active={activeIndex === 1} onClick={() => callback(1)}>
      <FontAwesomeIcon icon="table" />
    </Button>
    <Button outline active={activeIndex === 2} onClick={() => callback(2)}>
      <FontAwesomeIcon icon="chart-pie" />
    </Button>
    <Button outline active={activeIndex === 3} onClick={() => callback(3)}>
      <FontAwesomeIcon icon="random" />
    </Button>
    <Button outline active={activeIndex === 4} onClick={() => callback(4)}>
      <FontAwesomeIcon icon="sun" />
    </Button>
    <Button outline active={activeIndex === 5} onClick={() => callback(5)}>
      <FontAwesomeIcon icon="th" />
    </Button>
  </ButtonGroup>
);

DetailedPlanNav.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
};

class DetailedPlan extends React.Component {
  static propTypes = {
    mediaPlan: PropTypes.object.isRequired,
  };

  state = {
    activeTab: TABLE_INDEX,
  };

  setActiveTab = (activeTab) => this.setState({ activeTab });

  render() {
    const { activeTab } = this.state;
    const { mediaPlan } = this.props;

    return (
      <Card>
        <CardHeader>
          <Row>
            <Col>Detailed plan</Col>
            <Col style={{ textAlign: 'right' }}>
              <DetailedPlanNav
                activeIndex={this.state.activeTab}
                callback={this.setActiveTab}
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {activeTab === SANKEY_INDEX && <SankeyChart mediaPlan={mediaPlan} />}
          {activeTab === SUNBURST_INDEX && <Sunburst mediaPlan={mediaPlan} />}
          {activeTab === HEATMAP_INDEX && <HeatMap mediaPlan={mediaPlan} />}
          {activeTab === TABLE_INDEX && <RawTable mediaPlan={mediaPlan} />}
          {activeTab === DIMENSION_SPLIT_INDEX && (
            <DimensionSplit mediaPlan={mediaPlan} />
          )}
        </CardBody>
      </Card>
    );
  }
}

export default DetailedPlan;
