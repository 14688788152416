import { Button, ButtonGroup } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import Factor from './Factor';
import PropTypes from 'prop-types';
import TabsNav from '../TabsNav';

const DEFAULT_INITIAL_FREQUENCY = 3.0;
const INITIAL_OFFSETS = {};

function OstrowModel({
  initialFrequency = DEFAULT_INITIAL_FREQUENCY,
  factorsGroups = [],
  offsets = INITIAL_OFFSETS,
  setOffsets,
  frequencyCallback,
}) {
  const [visibleGroup, setVisibleGroup] = useState(0);
  const readOnly = typeof setOffsets !== 'function';
  const updateFactorOffset = (ids, offset) =>
    setOffsets({ ...offsets, [ids.factorId]: offset });
  const frequency = reduceFrequency(offsets, initialFrequency);
  useEffect(() => {
    if (frequencyCallback) {
      frequencyCallback(frequency);
    }
  }, [frequency]);

  const reset = () => {
    setOffsets(INITIAL_OFFSETS);
  };

  return (
    <div>
      <div className="mb-5">
        <TabsNav
          activeItem={{ id: visibleGroup }}
          onClick={(group) => setVisibleGroup(group.id)}
          items={factorsGroups.map((group, groupId) => ({
            id: groupId,
            Label: () => (
              <>
                {group.label}:{' '}
                <Offset value={reduceGroupOffset(group, offsets)} />
              </>
            ),
          }))}
        />
      </div>
      {factorsGroups.map((group, groupId) => (
        <div
          key={groupId}
          className={groupId === visibleGroup ? 'd-block mb-5' : 'd-none'}
        >
          {group.factors.map((factor, index) => (
            <Factor
              key={index}
              current={offsets[`${factor.id}`] || 0}
              callback={(offset) =>
                !readOnly &&
                updateFactorOffset({ groupId, factorId: factor.id }, offset)
              }
              edges={factor}
            />
          ))}
        </div>
      ))}
      {!readOnly && (
        <div className="text-right mb-3">
          <ButtonGroup>
            <Button outline onClick={reset}>
              Reset
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
}

export function Frequency({ value }) {
  return <span>{Number(value).toFixed(1)}</span>;
}

function Offset({ value }) {
  return (
    <span className="text-monospace">
      {signedNumber(Number(value).toFixed(1))}
    </span>
  );
}

function reduceFrequency(offsets, init) {
  return Object.values(offsets).reduce((acc, off) => acc + off, init);
}

function reduceGroupOffset(group, offsets) {
  const groupFactorIds = group.factors.map((f) => f.id);
  const keys = Object.keys(offsets).filter((fId) =>
    groupFactorIds.includes(parseInt(fId, 10))
  );
  const selection = keys.map((k) => offsets[k]);

  return reduceFrequency(selection, 0);
}

function signedNumber(num) {
  const signed = num > 0 ? '+' : num < 0 ? '-' : ' ';
  return (
    <>
      <span className="d-inline-block" style={{ width: '.7em' }}>
        {signed}
      </span>
      {num < 0 ? Number(num * -1).toFixed(1) : num}
    </>
  );
}

OstrowModel.propTypes = {
  initialFrequency: PropTypes.number,
  factorsGroups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      factors: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          lowEndLabel: PropTypes.string.isRequired,
          highEndLabel: PropTypes.string.isRequired,
          stepsCount: PropTypes.number.isRequired,
          stepsGap: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  setOffsets: PropTypes.func,
  offsets: PropTypes.object,
  frequencyCallback: PropTypes.func,
};

export default OstrowModel;
