import React, { useState } from 'react';

import OstrowModel from '../../components/OstrowModel';

export default function OstrowModelPreview() {
  const [offsets, setOffsets] = useState({});
  const props = {
    frequencyCallback: console.log,
    offsets,
    setOffsets,
    factorsGroups,
  };
  return (
    <section>
      <article>
        <h2>Edit mode</h2>
        <OstrowModel {...props} />
      </article>
      <article>
        <h2>Readonly mode</h2>
        <OstrowModel {...props} setOffsets={undefined} />
      </article>
    </section>
  );
}

const factorsGroups = [
  {
    label: 'Decision Process',
    factors: [
      {
        id: 1,
        lowEndLabel: 'Established brand',
        highEndLabel: 'New brand',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 2,
        lowEndLabel: 'Established product/service',
        highEndLabel: 'New product/service',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 3,
        lowEndLabel: 'High brand market share',
        highEndLabel: 'Low brand market share',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 4,
        lowEndLabel: 'High brand loyalty',
        highEndLabel: 'Low brand loyalty',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 5,
        lowEndLabel: 'High product/service differentiation',
        highEndLabel: 'Low product/service differentiation',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 6,
        lowEndLabel: 'Long purchase cycle',
        highEndLabel: 'Short purchase cycle',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 7,
        lowEndLabel: 'Less frequent usage',
        highEndLabel: 'Frequency usage',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 8,
        lowEndLabel: 'Low share of voice',
        highEndLabel: 'High share of voice',
        stepsCount: 5,
        stepsGap: 0.1,
      },
    ],
  },
  {
    label: 'Audience',
    factors: [
      {
        id: 9,
        lowEndLabel: 'Low customer lifetime value',
        highEndLabel: 'High customer lifetime value',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 10,
        lowEndLabel: 'Core audience',
        highEndLabel: 'Consumption pool',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 11,
        lowEndLabel: 'Indirect influencer',
        highEndLabel: 'Decision maker',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 12,
        lowEndLabel: 'Other group',
        highEndLabel: 'Elderly of childrens',
        stepsCount: 5,
        stepsGap: 0.1,
      },
    ],
  },
  {
    label: 'Creativity',
    factors: [
      {
        id: 13,
        lowEndLabel: 'Low complexity message',
        highEndLabel: 'High complexity message',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 14,
        lowEndLabel: 'High impact message',
        highEndLabel: 'Low impact message',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 15,
        lowEndLabel: 'Product/service-focused message',
        highEndLabel: 'Brand image-focused message',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 16,
        lowEndLabel: 'Rational message (features, promotion, availability)',
        highEndLabel: 'Emotional message (value, education)',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 17,
        lowEndLabel: 'Continuing campaign message',
        highEndLabel: 'New campaign message',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 18,
        lowEndLabel: 'Low message variety',
        highEndLabel: 'High message variety',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 19,
        lowEndLabel: 'High wearout',
        highEndLabel: 'Low wearout',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 20,
        lowEndLabel: 'High impact ad formats',
        highEndLabel: 'Low impact ad formats',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 21,
        lowEndLabel: 'Dynamic ad formats',
        highEndLabel: 'Static ad formats',
        stepsCount: 5,
        stepsGap: 0.1,
      },
    ],
  },
  {
    label: 'Media',
    factors: [
      {
        id: 22,
        lowEndLabel: 'Low clutter placement',
        highEndLabel: 'High clutter placement',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 23,
        lowEndLabel: 'Relevant environment',
        highEndLabel: 'Neutral environment',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 24,
        lowEndLabel: 'High attentiveness situation',
        highEndLabel: 'Low attentiveness situation',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 25,
        lowEndLabel: 'Long campaign flight',
        highEndLabel: 'Short campaign flight',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 26,
        lowEndLabel: 'Few touchpoints in the mix',
        highEndLabel: 'Many touchpoints in the mix',
        stepsCount: 5,
        stepsGap: 0.1,
      },
      {
        id: 27,
        lowEndLabel: 'High control over repetition across touchpoint',
        highEndLabel: 'Low control over repetition across touchpoint',
        stepsCount: 5,
        stepsGap: 0.1,
      },
    ],
  },
];
