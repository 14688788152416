import Amount from '../Amount';
import { Percentage } from 'happy-react-lib';
import PropTypes from 'prop-types';
import React from 'react';
import { Table as ReactstrapTable } from 'reactstrap';
import Select from 'react-select';
import max from 'lodash/max';
import styled from 'styled-components';
import sumBy from 'lodash/sumBy';

const MARGIN = 4;

function TacticCosts({ activeDimension, dimensions, metrics, callback }) {
  const computedWidth = max(dimensions.map((d) => d.label.length)) + MARGIN;

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <span className="mr-1">Breakdown costs estimates by</span>
        <div style={{ width: `${computedWidth}ch` }}>
          <Select clearable={false} value={activeDimension} onChange={callback} options={dimensions} />
        </div>
      </div>
      <Table responsive borderless hover>
        <thead>
          <Tr>
            <th>{activeDimension.label}</th>
            <th className="text-right">Budget</th>
            <th className="text-right" title="Cost per thousand contacts">
              CPM
            </th>
            <th className="text-right" title="Cost per click">
              CPC
            </th>
            <th className="text-right" title="Click through rate">
              CTR
            </th>
            <th className="text-right" title="Cost per contribution">
              CPA
            </th>
          </Tr>
        </thead>
        <tbody>
          {metrics.map((metric) => (
            <Metric key={metric.label} {...metric} />
          ))}
        </tbody>
        <Tfoot>
          <TotalMetric metrics={metrics} />
        </Tfoot>
      </Table>
    </div>
  );
}

TacticCosts.propTypes = {
  activeDimension: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  dimensions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      budget: PropTypes.number,
      contacts: PropTypes.number,
      clicks: PropTypes.number,
      conversions: PropTypes.number,
    })
  ),
  callback: PropTypes.func.isRequired,
};

function Metric({ label, budget, contacts, clicks, conversions }) {
  // cost per thousand contacts
  const cpm = contacts > 0 ? (budget * 1000) / contacts : 0;
  // cost per clicks
  const cpc = clicks > 0 ? budget / clicks : 0;
  // click through rate
  const ctr = contacts > 0 ? clicks / contacts : 0;
  // cost per conversions ( using A for attributions )
  const cpa = conversions > 0 ? budget / conversions : 0;

  return (
    <tr>
      <td>{label}</td>
      <td className="text-right">
        <Amount value={budget} />
      </td>
      <td className="text-right">
        <Amount value={cpm} showDecimals={true} />
      </td>
      <td className="text-right">
        <Amount value={cpc} showDecimals={true} />
      </td>
      <td className="text-right">
        <Percentage value={ctr} />
      </td>
      <td className="text-right">
        <Amount value={cpa} showDecimals={true} />
      </td>
    </tr>
  );
}

function TotalMetric({ metrics }) {
  const budget = sumBy(metrics, 'budget');
  const contacts = sumBy(metrics, 'contacts');
  const clicks = sumBy(metrics, 'clicks');
  const conversions = sumBy(metrics, 'conversions');

  return <Metric label="Total" budget={budget} contacts={contacts} clicks={clicks} conversions={conversions} />;
}

const Table = styled(ReactstrapTable)`
  font-size: 0.75em;
`;

const Tr = styled.tr`
  background-color: #6e757c;
  color: #fff;
  white-space: nowrap;
`;

const Tfoot = styled.tfoot`
  tr {
    background-color: #f7f7f7;
    border-top: 1px solid #6e757c;
  }
`;

export default TacticCosts;
