import 'lodash.combinations';

import _ from 'lodash';
import groupBy from 'lodash/groupBy';

export default function touchpointReachHelper(reach) {
  const perTouchpoints = groupBy(reach, 'touchpoint');
  return Object.keys(perTouchpoints).map((touchpoint) => ({
    touchpoint: touchpoint,
    color: perTouchpoints[touchpoint][0].color,
    reach: sainsbury(perTouchpoints[touchpoint].map((t) => t.reach)),
  }));
}

function sainsbury(values, correctionFactor = 0.075) {
  const product = values.map((v) => 1 - v).reduce((acc, v) => acc * v, 1);

  return (1 - product) * (1 - correctionFactor);
}

export function multitouchpointReach(data, correctionFactor = 0.075) {
  const values = data.map((t) => t.reach);
  return sainsbury(values, correctionFactor);
}

export function touchpointOverlap(reach) {
  const data = touchpointReachHelper(reach);
  const touchpoints = data.map((d) => d.touchpoint);
  const maxSize = touchpoints.length;
  let size = 2;
  const sets = [];
  while (size <= maxSize) {
    const set = _.combinations(touchpoints, size);
    sets.push(set);
    size += 1;
  }

  return sets
    .map((set) =>
      set.map((s) => ({
        touchpoints: s,
        overlap: s
          .map((touchpoint) => data.find((t) => t.touchpoint === touchpoint))
          .map((t) => t.reach)
          .reduce((acc, v) => acc * v, 1),
      }))
    )
    .flat();
}

export function touchpointExclusiveReach(reach) {
  const data = touchpointReachHelper(reach);
  const reaches = data.map((t) => t.reach);

  return data.map((t, index) => {
    const exclusiveReach = reaches.map((reach, j) => (index === j ? reach : 1 - reach)).reduce((acc, v) => acc * v, 1);

    return {
      touchpoint: t.touchpoint,
      color: t.color,
      exclusiveReach,
      sharedReach: reaches[index] - exclusiveReach,
    };
  });
}
