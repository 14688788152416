import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Touchpoint from './PlanOverview/Touchpoint';
import TouchpointsModal from './PlanOverview/TouchpointsModal';
import styled from 'styled-components';

const Ul = styled.ul`
  &:hover {
    cursor: pointer;
  }

  li {
    width: 48px;
  }
`;

export default function TouchpointsSelectionList({
  allTouchpoints,
  selectedTouchpoints,
}) {
  const [opened, setOpened] = useState(false);
  const toggleModal = () => setOpened(!opened);
  return (
    <>
      <Ul className="list-inline" onClick={toggleModal}>
        {allTouchpoints.map((touchpoint) => {
          const active = selectedTouchpoints.includes(touchpoint);
          return (
            <li
              key={touchpoint.id}
              className={`list-inline-item ${
                !active && 'd-none d-md-inline-block'
              }`}
            >
              <Touchpoint
                touchpoint={touchpoint}
                small={true}
                active={active}
              />
            </li>
          );
        })}
      </Ul>
      <TouchpointsModal
        open={opened}
        toggle={toggleModal}
        touchpoints={allTouchpoints}
        selection={selectedTouchpoints}
      />
    </>
  );
}

TouchpointsSelectionList.propTypes = {
  allTouchpoints: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedTouchpoints: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};
