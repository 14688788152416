import ColumnChart from '../../components/TouchpointCoverage/ColumnChart';
import React from 'react';

export default function ColumnChartPreview() {
  const params = {
    title: 'My chart',
    yTitle: 'Contacts',
    data: [
      {
        name: 'Yoann',
        y: 42,
      },
    ],
  };

  console.log(params);

  return <ColumnChart {...params} />;
}
