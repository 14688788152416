import FunnelChart from '../../components/FunnelChart';
import React from 'react';

export default function FunnelPreview() {
  const props = {
    data: [
      ['Contacts', 58182],
      ['Viewable contacts', 40727.4],
      ['Viewable contacts on target', 28509.18],
      ['Viewable contacts on target exposed with optimal frequency', 9503],
      [
        'Deduplicated viewable contacts on target exposed at optimal frequency across touchpoints',
        6652,
      ],
    ],
  };

  return <FunnelChart {...props} />;
}
