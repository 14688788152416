import React, { useState } from 'react';

import NoteThroughModal from './NoteThroughModal';
import PropTypes from 'prop-types';
import ReadOnlyNoteArea from './ReadOnlyNoteArea';
import TabsNav from './TabsNav';

export default function TacticNotes({ notes }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const tabs = notes.map((note, index) => ({
    id: index,
    label: note.name,
  }));
  const activeNote = notes[activeIndex];

  return (
    <div>
      <div className="mb-3">
        <TabsNav
          activeItem={tabs[activeIndex]}
          items={tabs}
          onClick={({ id }) => setActiveIndex(id)}
        />
      </div>
      <ReadOnlyNoteArea
        contentAvailable={true}
        dangerouslySetInnerHTML={{ __html: activeNote.content }}
      />
      {activeNote.onChange && (
        <>
          <NoteThroughModal
            content={activeNote.content}
            onChange={activeNote.onChange}
            header={activeNote.name}
            cta="Edit"
          />
        </>
      )}
    </div>
  );
}

TacticNotes.propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      onChange: PropTypes.func,
    })
  ).isRequired,
};
