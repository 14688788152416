import { REVIEW_NEEDED, VALIDATED, VALIDATION_PENDING } from '../../constants';

import CampaignPlanSharedHeader from '../../components/CampaignPlanSharedHeader';
import React from 'react';

export default function CampaignPlanSharedHeaderPreview() {
  const props = {
    status: REVIEW_NEEDED,
    brand: 'My Brand',
    client: 'A client',
    country: 'United States of America',
    updatedAt: '2020-07-09T21:17:23.868Z',
    mediaPlan: {
      getTotalBudget: () => 42000,
      getFlightDates: () => ['12-May-1980', '12-May-2022'],
    },
  };

  return (
    <div>
      <CampaignPlanSharedHeader {...props} />
      <hr />
      <CampaignPlanSharedHeader {...props} status={VALIDATION_PENDING} />
      <hr />
      <CampaignPlanSharedHeader {...props} status={VALIDATED} />
    </div>
  );
}
