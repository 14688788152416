import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';

import FieldGroup from '../FieldGroup';
import HappyficationDataDisclaimer from '../HappyficationDataDisclaimer';
import { Mutation } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import React from 'react';
import { gql } from '@apollo/client';

function isValidEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

const ADD_CHANGE_REQUEST = gql`
  mutation AddChangeRequest(
    $commentableId: Int!
    $name: String!
    $email: String!
    $content: String!
  ) {
    addChangeRequest(
      commentableType: "CampaignPlan"
      commentableId: $commentableId
      name: $name
      email: $email
      content: $content
    ) {
      name
      email
    }
  }
`;

class RequestChangeModal extends React.Component {
  state = {
    name: null,
    email: null,
    content: null,
    errors: null
  };

  static propTypes = {
    campaignPlanId: PropTypes.number.isRequired,
    commentable: PropTypes.bool.isRequired,
    toggleRequestChangeModal: PropTypes.func.isRequired,
    updateStatus: PropTypes.func.isRequired
  };

  submitForm = (event, gqlMutation) => {
    const { name, email, content } = this.state;
    const errors = { empty: true };

    event.preventDefault();
    if (name === null || name === '') {
      errors.name = 'error';
      errors.empty = false;
    }
    if (!isValidEmail(email)) {
      errors.email = 'error';
      errors.empty = false;
    }
    if (content === null || content === '') {
      errors.content = 'error';
      errors.empty = false;
    }

    if (errors.empty) {
      gqlMutation({
        variables: {
          commentableId: this.props.campaignPlanId,
          name,
          email,
          content
        }
      });
      this.props.toggleRequestChangeModal();
      this.props.updateStatus(0);
    } else {
      this.setState({ errors });
    }
  };

  handleInputChange = event => {
    const target = event.target;
    const { name, value } = target;

    this.setState({
      [name]: value
    });
  };

  render() {
    const { processing, commentable, toggleRequestChangeModal } = this.props;

    const { errors } = this.state;

    return (
      <Modal isOpen={commentable} toggle={toggleRequestChangeModal} size="lg">
        <ModalHeader toggle={toggleRequestChangeModal}>
          Campaign plan change request
        </ModalHeader>
        <form>
          <ModalBody>
            <p>
              Please use the form below to automatically submit your change
              request to the team.
            </p>
            <p>
              We will update the campaign plan accordingly and get back to you
              with a new version.
            </p>
            <FormGroup>
              <Label>Request</Label>
              <Input
                name="content"
                type="textarea"
                placeholder="Enter your request"
                rows={9}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FieldGroup
              id="authorName"
              name="name"
              type="text"
              label="Name"
              state={errors && errors.name}
              placeholder="Enter your name"
              onChange={this.handleInputChange}
            />
            <FieldGroup
              id="authorEmail"
              name="email"
              type="email"
              label="Email"
              state={errors && errors.email}
              placeholder="Enter your email"
              onChange={this.handleInputChange}
            />

            {processing && <p className="text-center">Processing...</p>}
          </ModalBody>

          {!processing && (
            <React.Fragment>
              <ModalFooter>
                <Mutation mutation={ADD_CHANGE_REQUEST}>
                  {addChangeRequest => (
                    <Button
                      type="submit"
                      onClick={event =>
                        this.submitForm(event, addChangeRequest)
                      }
                      outline
                      color="danger"
                    >
                      Request change
                    </Button>
                  )}
                </Mutation>
              </ModalFooter>
              <HappyficationDataDisclaimer />
            </React.Fragment>
          )}
        </form>
      </Modal>
    );
  }
}

export default RequestChangeModal;
