import { Nav, NavItem, NavLink } from 'reactstrap';

import PropTypes from 'prop-types';
import React from 'react';

function TabsNav({ activeItem, items, onClick }) {
  return (
    <Nav tabs className="flex-nowrap">
      {items &&
        items.map((item) => (
          <NavItem key={item.id}>
            <NavLink
              href="#"
              className={activeItem.id === item.id ? 'active' : ''}
              style={{
                color: activeItem.id === item.id ? '#212529' : '#b7b7b7',
                fontWeight: activeItem.id === item.id ? 'bold' : '400',
              }}
              onClick={() => onClick(item)}
            >
              {item.prefix && (
                <React.Fragment>
                  <span>{item.prefix}</span>
                  <br />
                </React.Fragment>
              )}
              {item.label || <item.Label />}
            </NavLink>
          </NavItem>
        ))}
    </Nav>
  );
}

TabsNav.propTypes = {
  activeItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string,
      Label: PropTypes.func,
    })
  ),
  onClick: PropTypes.func.isRequired,
};

export default TabsNav;
