import { CardText, CardTitle, Col, Input, Row } from 'reactstrap';
import { ColorsHelper, Number, Percentage } from 'happy-react-lib';
import { ContactDistribution, ExclusiveReach, TouchpointOverlap } from '../../MultiTouchpointsReach';

import DeliveryReach from '../../DeliveryReach';
import DimensionSplit from '../DimensionSplit';
import EditOnly from '../../EditOnly';
import EstimatedContactsOnTarget from './EstimatedContactsOnTarget';
import EstimatedDelivery from '../../EstimatedDelivery';
import EstimatedOverlap from '../../TouchpointCoverage/EstimatedOverlap';
import FunnelChart from '../../FunnelChart';
import PropTypes from 'prop-types';
import React from 'react';
import TagNameContext from '../../../contexes/TagNameContext';
import TouchpointsReachList from '../../TouchpointsReachList';
import TrueTargetRatingPoint from '../../TrueTargetRatingPoint';
import loadTouchpoints from '../../../queries/touchpoints';
import { multitouchpointReach } from '../../MultiTouchpointsReach/touchpointReachHelper';
import sum from 'lodash/sum';
import touchpointLabelMatcher from '../../../utils/touchpoint_label_matcher';
import { colorListAt } from '../../../utils/colors';

const GOLDEN_THRESHOLD_PERCENTAGE = 70 / 100;

const contacts = (t) => t.getContacts();
const viewableContacts = (t) => t.getViewableContacts() || (contacts(t) * t.percentageOfViewability) / 100;
const viewableContactsOnTarget = (t) =>
  t.getViewableContactsOnTarget() || viewableContacts(t) * (t.onTargetPercentage / 100);

class CoverageStrength extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    reach: PropTypes.number.isRequired,
    audienceSize: PropTypes.number.isRequired,
    budget: PropTypes.number.isRequired,
    totalOptimalFrequency: PropTypes.number.isRequired,
    initialAvgOptimalFrequency: PropTypes.number,
    initialEstimatedReach: PropTypes.number,
    initialTrueTargetRatingPoint: PropTypes.number,
    selection: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      touchpoints: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          label: PropTypes.string.isRequired,
          onTargetPercentage: PropTypes.number.isRequired,
          coverageOverlap: PropTypes.number.isRequired,
        })
      ),
    }),
  };

  state = {
    optimalFrequency: null,
    trueTargetRatingPoint: null,
    audienceSize: null,
    multiTouchpointReach: 0,
    averageFrequency: null,
  };

  constructor(props) {
    super(props);
  }

  // TODO:
  // - capter le reach calcule par le multimedia reach lu dans le fichier.
  // - fix: changement de frequency
  // - add a sentence: Multimedia reach 1+: XX% with an average frequency of XX.X

  static getDerivedStateFromProps(props, state) {
    if (props.audienceSize !== state.audienceSize) {
      const mtReach = multitouchpointReach(
        props.details.map((d) => ({
          touchpoint: d.touchpoint,
          reach: d.reach ? d.reach : d.viewableContactsOnTarget / d.optimalFrequency / props.audienceSize,
        }))
      );
      const estimatedViewableContactsOnTarget = sum(props.details.map((d) => d.viewableContactsOnTarget));
      const overallOptimalFrequency = estimatedViewableContactsOnTarget / (props.audienceSize * mtReach);
      return {
        trueTargetRatingPoint: Math.round(overallOptimalFrequency * mtReach * 100),
        optimalFrequency:
          Math.round(
            (props.initialAvgOptimalFrequency > 0 ? props.initialAvgOptimalFrequency : overallOptimalFrequency) * 10
          ) / 10,
        multiTouchpointReach: mtReach,
        averageFrequency: overallOptimalFrequency,
        audienceSize: props.audienceSize,
      };
    }

    return null;
  }

  setOptimalFrequency = ({ target }) => {
    console.log(target.value);
    const f = parseFloat(target.value, 10);
    this.setState({ optimalFrequency: f > 0 ? f : 1 });
  };

  render() {
    const { data, selection, totalOptimalFrequency, index } = this.props;
    if (data.loading) {
      return null;
    }
    const touchpoints = selection.map((s, index) => ({
      ...data.touchpoints.find((t) => touchpointLabelMatcher(s.label, t.label)),
      color: colorListAt(index),
      getBudget: s.getBudget,
      getContacts: s.getContacts,
      getCEOF: s.getCEOF,
      getViewableContacts: s.getViewableContacts,
      getViewableContactsOnTarget: s.getViewableContactsOnTarget,
    }));
    const estimatedContacts = sum(touchpoints.map(contacts));
    const estimatedViewableContacts = sum(touchpoints.map(viewableContacts));
    const estimatedViewableContactsOnTarget = sum(touchpoints.map(viewableContactsOnTarget));
    const estimatedViewableContactsOnTargetWithOptimalFrequency = Math.round(
      estimatedViewableContactsOnTarget / this.state.optimalFrequency
    );
    const mtReach = Math.min(
      this.state.trueTargetRatingPoint / this.state.optimalFrequency / 100,
      this.state.multiTouchpointReach
    );
    const deduplicatedContacts = mtReach * this.props.audienceSize;
    const threshold = Math.round(GOLDEN_THRESHOLD_PERCENTAGE * this.props.audienceSize);
    const params = {
      actual: Math.floor(mtReach * this.props.audienceSize),
      max: this.props.audienceSize,
      threshold,
      yAxisTitle: '',
      serieName: '',
    };
    const { audienceName } = this.props;

    return (
      <CardText tag="div">
        <CardTitle tag="h5">Estimated delivery</CardTitle>
        <Row className="mb-5">
          <Col md={5}>
            <EstimatedDelivery
              contacts={estimatedContacts}
              viewableContacts={estimatedViewableContacts}
              viewableContactsOnTarget={estimatedViewableContactsOnTarget}
              deduplicated={deduplicatedContacts}
            />
          </Col>
          <Col md={7}>
            <FunnelChart
              data={[
                ['contacts', estimatedContacts],
                ['viewable', estimatedViewableContacts],
                ['on target', estimatedViewableContactsOnTarget],
                // ['with optimal frequency', estimatedViewableContactsOnTargetWithOptimalFrequency],
                ['deduplicated', deduplicatedContacts],
              ]}
            />
          </Col>
        </Row>
        <EditOnly>
          <Row className="mb-5">
            <Col md={5} className="pt-2">
              <p className="mb-1">Tactic settings:</p>
              <Row noGutters={true} className="mb-1">
                <Col xs={8}>
                  <label htmlFor="">Optimal frequency across touchpoints:</label>
                </Col>
                <Col xs={2}>
                  <Input
                    key={`${Math.floor(Math.random() * 1000)}-min`}
                    type="number"
                    bsSize="sm"
                    defaultValue={this.state.optimalFrequency}
                    onChange={this.setOptimalFrequency}
                  />
                </Col>
                <Col xs={2}>&nbsp; times</Col>
              </Row>
            </Col>
          </Row>
        </EditOnly>
        <Row className="mb-5">
          <Col md={8}>
            <p>
              We estimate a{' '}
              <b>
                <Percentage value={this.state.multiTouchpointReach} /> viewable reach with 1+ frequency
              </b>{' '}
              across all media touchpoints, and a{' '}
              <b>{Math.round(this.state.averageFrequency * 10) / 10} average frequency</b>.
            </p>
            <DeliveryReach
              audienceName={audienceName}
              audienceSize={this.props.audienceSize}
              {...params}
              optimalFrequency={parseFloat(this.state.optimalFrequency, 10)}
            />
          </Col>
          <Col md={4}>{params.max > 0 && <TrueTargetRatingPoint rating={this.state.trueTargetRatingPoint} />}</Col>
        </Row>
        <TagNameContext.Consumer>
          {(inputs) => (
            <React.Fragment>
              <input
                type="hidden"
                value={this.state.optimalFrequency}
                name={
                  (inputs &&
                    inputs.campaign_plan_tactics &&
                    inputs.campaign_plan_tactics[this.props.index] &&
                    inputs.campaign_plan_tactics[this.props.index].avgOptimalFrequency.tagName) ||
                  `campaign_plan[campaign_plan_tactics_attributes][${this.props.index}][avg_optimal_frequency]`
                }
              />
            </React.Fragment>
          )}
        </TagNameContext.Consumer>
        <Row className="mb-5">
          <Col md={4}>
            <DimensionSplit
              dimensions={[{ label: 'Touchpoint' }]}
              nodes={touchpoints}
              metric="Viewable contacts"
              metricCallback="getViewableContacts"
            />
          </Col>
          <Col md={4}>
            <EstimatedContactsOnTarget
              data={touchpoints.map((t) => ({
                name: t.label,
                color: t.color,
                onTarget: viewableContactsOnTarget(t),
                viewableContacts: viewableContacts(t),
              }))}
            />
          </Col>
          <Col md={4}>
            <header>
              <h5>
                On target reach
                <br />
                (Viewable contacts)
              </h5>
            </header>
            <ExclusiveReach
              reach={touchpoints.map((t) => ({
                ...t,
                touchpoint: t.label,
                reach: viewableContactsOnTarget(t) / this.state.optimalFrequency / params.max,
              }))}
            />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={4}>
            <header>
              <h5>
                Overlaps
                <br />
                (People)
              </h5>
            </header>
            <TouchpointOverlap
              audienceSize={params.max}
              reach={touchpoints.map((t) => ({
                ...t,
                touchpoint: t.label,
                reach: viewableContactsOnTarget(t) / this.state.optimalFrequency / params.max,
                peopleReached: viewableContactsOnTarget(t) / this.state.optimalFrequency,
              }))}
            />
          </Col>
          <Col>
            <div style={{ fontSize: '11px' }}>
              <TouchpointsReachList
                touchpoints={data.touchpoints}
                selection={touchpoints.map((t) => ({
                  ...t,
                  peopleReached: viewableContactsOnTarget(t) / this.state.optimalFrequency,
                }))}
              />
            </div>
          </Col>
        </Row>
        {!isNaN(this.state.trueTargetRatingPoint) && this.state.trueTargetRatingPoint > 0 && (
          <ContactDistribution
            sumOfOptimalFrequency={totalOptimalFrequency}
            trueTargetRatingPoints={this.state.trueTargetRatingPoint}
          />
        )}
      </CardText>
    );
  }
}

export default loadTouchpoints(CoverageStrength);
