import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleModal from '../SimpleModal';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0.75rem 0 1.1rem;
`;
class TacticStrategy extends React.Component {
  static propTypes = {
    strategy: PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  };

  state = {
    modalOpened: false,
    benchmarks: null,
  };

  toggleModal = () => this.setState({ modalOpened: !this.state.modalOpened });

  render() {
    const { strategy } = this.props;

    return (
      <Wrapper>
        <Button outline onClick={this.toggleModal}>
          Review related efficiency and effectiveness KPI’s
        </Button>
        <SimpleModal
          open={this.state.modalOpened}
          toggle={this.toggleModal}
          header={strategy.label}
          size="lg"
        >
          <div dangerouslySetInnerHTML={{ __html: strategy.description }} />
        </SimpleModal>
      </Wrapper>
    );
  }
}

export default TacticStrategy;
