import { Col, Row } from 'reactstrap';

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const CenteredCol = styled(Col)`
  text-align: center;
`;

const Dimensions = ({ dimensions }) => (
  <Row>{dimensions.map(d => <CenteredCol>{d.label}</CenteredCol>)}</Row>
);

Dimensions.propTypes = {
  dimensions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    })
  )
};

export default Dimensions;
