import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import ChartTitle from '../ChartTitle';
import DropdownNav from '../DropdownNav';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PieChart from 'happy-react-lib/build/components/PieChart';
import React from 'react';
import SunburstChart from './Chart';
import SunburstNav from './SunburstNav';
import times from 'lodash/times';

const HighchartsProxy = ({ config }) => <HighchartsReact options={config} highcharts={Highcharts} />;

class Sunburst extends React.Component {
  state = {
    currentPoint: null,
    dimension: '',
    metric: '',
  };

  constructor(props) {
    super(props);
    const { mediaPlan } = this.props;
    this.dimensions = mediaPlan.getDimensions();
    // if (this.dimensions && this.dimensions.length > 0) {
    //   this.state.dimension = this.dimensions[0];
    // }
    this.metrics = mediaPlan.getSummableMetrics();
    if (this.metrics && this.metrics.length > 0) {
      this.state.metric = this.metrics[0];
    }
  }

  setCurrentPoint = (currentPoint) => this.setState({ currentPoint });
  setMetric = (metric) => this.setState({ metric });
  setDimension = (dimension) => this.setState({ dimension });

  getDeepestDimensions = () => {
    const { currentPoint } = this.state;

    const dimensionIndex = currentPoint ? this.dimensions.findIndex((d) => d.label === currentPoint.type) : -1;

    return this.dimensions.slice(dimensionIndex + 1);
  };

  formatData = () => {
    const { mediaPlan } = this.props;
    const { metric } = this.state;

    const data = mediaPlan.getStructure().map((node) => {
      const value = mediaPlan.getAggregateMetric(metric.id)(node);
      return {
        id: `${node.id}`,
        parent: node.parentId ? `${node.parentId}` : '0',
        name: node.label,
        value,
        type: node.type,
        ceof: mediaPlan.getCEOF(node),
        contacts: mediaPlan.getContacts(node),
      };
    });

    // add root
    data.push({
      id: '0',
      parent: null,
      name: 'Campaign',
      value: mediaPlan.getAggregateMetric(metric.id)({ id: null }),
      type: 'Campaign',
      ceof: mediaPlan.getTotalCEOF(),
      contacts: mediaPlan.getTotalContacts(),
    });

    return data;
  };

  formatPieData = (currentPoint) => {
    const { mediaPlan } = this.props;
    const nodes = mediaPlan.getChildrenByType(
      currentPoint && currentPoint.id === '0' ? { id: null } : currentPoint,
      this.state.dimension.label
    );
    const budget = {};
    nodes.forEach((node) => {
      budget[node.label] = budget[node.label] || 0;
      const value = mediaPlan.getAggregateMetric(this.state.metric.id)(node);
      budget[node.label] = budget[node.label] + value;
    });

    const data = Object.keys(budget).map((name) => ({
      name,
      y: budget[name],
    }));

    return data;
  };

  // level 1: root, should be transparent
  // level 2 ... mediaPlan.depth: should be colored with variation after first level with multiple children
  levelConfig = () => {
    const { mediaPlan } = this.props;
    const dimensions = mediaPlan.getDimensions();
    const depth = mediaPlan.getDepth();
    let applyColorVariation = false;
    let variation = -1;

    return times(depth + 1, (level) => {
      // apply color to transparent for center level
      const color = level === 0 ? 'transparent' : undefined;
      // colorByPoint only while we don't apply a colorVariation
      const colorByPoint = !applyColorVariation;
      // labels using parallel display for first 2
      // labels using perpendicular display either
      const rotationMode = level < 2 ? 'parallel' : 'perpendicular';
      // if we haven't reach a level decomposing more that one higher level element
      if (level > 0 && !applyColorVariation) {
        const dimension = dimensions[level - 1];
        applyColorVariation = mediaPlan.getStructureByType(dimension.label).length > 1;
      }
      const result = {
        level: level + 1,
        color,
        colorByPoint,
        dataLabels: {
          rotationMode,
        },
      };
      if (level === 0) {
        result.dataLabels = {
          rotationMode: 'parallel',
          filter: {
            property: 'outerArcLength',
            operator: '>',
            value: 64,
          },
        };
      }
      if (applyColorVariation) {
        result.colorVariation = {
          key: 'brightness',
          to: 0.5 * variation,
        };

        variation = variation * -1;
      }

      return result;
    });
  };

  render() {
    const data = this.formatData();
    let pieData = [];
    const levelConfiguration = this.levelConfig();
    const currentPoint = this.state.currentPoint || data[data.length - 1];

    if (data.length === 0) {
      return <div>Empty plan</div>;
    }

    if (currentPoint) {
      pieData = this.formatPieData(currentPoint);
    }

    return (
      <React.Fragment>
        <ChartTitle>Split</ChartTitle>
        <Row>
          <Col md={6}>
            <SunburstChart
              metric={this.state.metric}
              data={data}
              levelConfiguration={levelConfiguration}
              onClick={this.setCurrentPoint}
            />
          </Col>
          <Col md={6}>
            <DropdownNav activeItem={this.state.metric} items={this.metrics} onClick={this.setMetric} />
            <br />
            {currentPoint && (
              <Card>
                <CardHeader>{currentPoint.name}</CardHeader>
                <CardBody>
                  <SunburstNav point={currentPoint} label={this.state.metric.label} />
                  <p>
                    <b>Analyze contribution per</b>
                  </p>
                  <DropdownNav
                    activeItem={this.state.dimension}
                    items={this.getDeepestDimensions()}
                    onClick={this.setDimension}
                  />
                  <PieChart series={pieData} name={this.state.metric.label} ChartGenericComponent={HighchartsProxy} />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export function isFinancialLabel(label) {
  if (label) {
    return label.match(/budget/i) || label.match(/costs/i) || label.match(/CEOF/);
  }

  return false;
}
export default Sunburst;
