import EstimatedDelivery from '../../components/EstimatedDelivery';
import React from 'react';

export default function EstimatedDeliveryPreview() {
  const props = {
    contacts: 42420,
    viewableContacts: 4200,
    viewableContactsOnTarget: 2500,
    viewableContactsOnTargetWithOptimalFrequency: 1000,
    deduplicated: 420,
  };

  return <EstimatedDelivery {...props} />;
}
