import styled from 'styled-components';

const ReadOnlyNoteArea = styled.div`
  ${({ contentAvailable }) =>
    contentAvailable
      ? `background-color: rgba(0, 0, 0, 0.03);`
      : `
    color: #a94442;
    background-color: #f2dede;
  `}
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 0.3rem;
  font-size: 1rem;
`;

export default ReadOnlyNoteArea;
