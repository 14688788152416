import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

export const DROPDOWN_HEIGHT = '34px';

class DropdownNav extends React.Component {
  static propTypes = {
    activeItem: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ]),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    onClick: PropTypes.func.isRequired,
    clearable: PropTypes.bool,
  };

  static defaultProps = {
    clearable: false,
  };

  toOption = (item) =>
    item
      ? {
          value: item.id,
          label: item.label,
        }
      : null;

  toOptions = (items) => items.map(this.toOption);

  onChange = (option) => {
    this.props.onClick(
      option
        ? {
            id: option.value,
            label: option.label,
          }
        : null
    );
  };

  render() {
    const activeItem = this.toOption(this.props.activeItem);
    const options = this.toOptions(this.props.items);

    return (
      <Select
        value={activeItem}
        onChange={this.onChange}
        options={options}
        clearable={this.props.clearable}
      />
    );
  }
}

export default DropdownNav;
