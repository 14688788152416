import { ColorsHelper } from 'happy-react-lib';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

export default function OnTargetReachChart({ data }) {
  const categories = data.map((d) => d.touchpoint);
  const max = Math.max(...data.map((d) => [d.exclusiveReach, d.sharedReach]).flat()) * 100;
  const options = {
    chart: {
      type: 'column',
      events: {
        load() {
          const chart = this;

          chart.renderer
            .text('Drive penetration', chart.plotWidth - 80, 23)
            .attr({
              zIndex: 3,
            })
            .css({
              color: '#212529',
            })
            .add();
          chart.renderer
            .text('Drive frequency', chart.plotWidth - 75, chart.plotHeight + 5)
            .attr({
              zIndex: 3,
            })
            .css({
              color: '#212529',
            })
            .add();
        },
      },
    },
    title: null,
    xAxis: [
      {
        categories,
      },
    ],
    yAxis: {
      labels: {
        formatter: function () {
          if (this.value === 0) {
            return `<b>${Math.abs(this.value)}%</b>`;
          }
          return `${Math.abs(this.value)}%`;
        },
      },
      reversed: true,
      title: null,
      min: -1 * max,
      max: max,
      plotLines: [
        {
          color: '#E2E2E2',
          width: 2,
          value: 0,
        },
      ],
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        pointPadding: 0.25,
      },
    },
    tooltip: {
      formatter: function () {
        var s = '<b>' + this.x + '</b>';

        this.points.forEach(function (point) {
          s += '<br/>' + point.series.name + ': ' + Highcharts.numberFormat(Math.abs(point.y), 2) + '%';
        });

        return s;
      },
      shared: true,
    },
    series: [
      {
        name: 'Penetration / Exclusive reach',
        data: data.map((t) => ({ y: -1 * t.exclusiveReach * 100, color: t.color })),
      },
      {
        name: 'Frequency / Shared reach',
        data: data.map((t) => ({ y: t.sharedReach * 100, color: ColorsHelper.colorMultiply(t.color, 0.9) })),
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
