const CONVERGENCE_LIMIT = 0.0006;
const FREQUENCY_CAP = 100;

export default function contactDistributionHelper(n, p) {
  const reachedAtOptimalFrequency = [];
  let frequency = 1;
  let percentage = 0;
  let aboveLimitOnce = false;
  do {
    percentage = combinations(n, frequency) * Math.pow(p, frequency) * Math.pow(1 - p, n - frequency);
    reachedAtOptimalFrequency.push(percentage);
    frequency += 1;
    if (percentage > CONVERGENCE_LIMIT) {
      aboveLimitOnce = true;
    }
  } while (frequency < FREQUENCY_CAP && (aboveLimitOnce === false || percentage > CONVERGENCE_LIMIT));

  return reachedAtOptimalFrequency;
}

function productRange(a, b) {
  let product = a;
  let i = a;

  while (i++ < b) {
    product *= i;
  }

  return product;
}

function combinations(n, r) {
  if (n === r) {
    return 1;
  } else {
    r = r < n - r ? n - r : r;
    return productRange(r + 1, n) / productRange(1, n - r);
  }
}
