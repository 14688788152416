import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import React from 'react';

class ColumnChart extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string),
    yTitle: PropTypes.string.isRequired,
    yUnit: PropTypes.string,
    dataLabelFormat: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        y: PropTypes.number.isRequired,
        color: PropTypes.string.isRequired,
      })
    ),
  };

  render() {
    const propsData = this.props.data;

    const config = {
      chart: {
        type: 'column',
      },
      title: {
        text: this.props.title,
      },
      xAxis: {
        categories: this.props.categories,
        labels: {
          useHTML: true,
          formatter: function () {
            const value = this.value;
            const color = propsData[this.pos].color;

            return `<p style='text-align: right; color: ${color}'>${value}</p>`;
          },
        },
      },
      yAxis: {
        title: { text: this.props.yTitle },
        labels: {
          format: `{value:,.0f}`,
        },
      },
      tooltip: {
        backgroundColor: 'rgba(255,255,255, 1)',
        shared: true,
        valueSuffix: this.props.yUnit,
        pointFormat: '<b>{point.y}</b>',
        valueDecimals: 0,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: !!this.props.dataLabelFormat,
            format: this.props.dataLabelFormat,
          },
        },
      },
      series: [
        {
          name: this.props.title,
          data: this.props.data,
        },
      ],
    };

    return <HighchartsReact highcharts={Highcharts} options={config} />;
  }
}

export default ColumnChart;
