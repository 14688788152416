import flatten from 'lodash/flatten';
import max from 'lodash/max';
import min from 'lodash/min';
import moment from 'moment';
import numeral from 'numeraljs';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';

const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
// const DIMENSION_CUT_LABEL = 'FLIGHT START';
const TOTAL_BUDGET_LABEL = 'TOTAL BUDGET';
const FLIGHT_START_LABEL = 'FLIGHT START';
const FLIGHT_END_LABEL = 'FLIGHT END';
// const DIMENSION_CUT_LABEL = 'BUDGET SPLIT';
const CEOF_LABEL = 'CONTACTS EXPOSED WITH OPTIMAL FREQUENCY';
const CONTACTS_LABEL = 'CONTACTS';
const VIEWABLE_CONTACTS_LABEL = 'VIEWABLE CONTACTS';
const VIEWABLE_CONTACTS_ON_TARGET_LABEL = 'VIEWABLE CONTACTS ON TARGET';
const CLICKS_LABEL = 'CLICKS';
const CONVERSIONS_LABEL = 'CONVERSIONS';
const OPTIMAL_FREQUENCY_LABEL = 'OPTIMAL FREQUENCY';
const REACH_LABEL = 'REACH';
const TOUCHPOINT_LABEL = 'TOUCHPOINT';
const HEADER_ROW = 0;

const SUMMABLE_METRIC_LABELS = [
  'Total budget',
  'Media costs',
  'Data costs',
  'Intelligence platform costs',
  'Adserving costs',
  'DCO costs',
  'Viewability, brand safety & fraud costs',
  'Declarative data panel costs',
  'Miscellaneous costs',
  'Contacts',
  'Viewable Contacts',
  'Viewable Contacts on Target',
  'Viewable impressions',
  'Contacts Exposed With Optimal Frequency',
  'CCEOF',
  'Clicks',
  'Engagements',
  'Completed views',
  'Conversions',
  'Acquisitions',
  'Revenue',
  'Subscriptions',
];

class Parser {
  constructor(input) {
    this.input = input;
    if (input && input.length > 0) {
      this.flightStartIndex = this.input[HEADER_ROW].findIndex((l) => l && l.toUpperCase() === FLIGHT_START_LABEL);
      this.flightEndIndex = this.input[HEADER_ROW].findIndex((l) => l && l.toUpperCase() === FLIGHT_END_LABEL);
      this.totalBudgetIndex = this.input[HEADER_ROW].findIndex((l) => l && l.toUpperCase() === TOTAL_BUDGET_LABEL);
      this.ceofIndex = this.input[HEADER_ROW].findIndex((l) => l && l.toUpperCase() === CEOF_LABEL);
      this.contactsIndex = this.input[HEADER_ROW].findIndex((l) => l && l.toUpperCase() === CONTACTS_LABEL);
      this.viewableContactsIndex = this.input[HEADER_ROW].findIndex(
        (l) => l && l.toUpperCase() === VIEWABLE_CONTACTS_LABEL
      );
      this.viewableContactsOnTargetIndex = this.input[HEADER_ROW].findIndex(
        (l) => l && l.toUpperCase() === VIEWABLE_CONTACTS_ON_TARGET_LABEL
      );
      this.clicksIndex = this.input[HEADER_ROW].findIndex((l) => l && l.toUpperCase() === CLICKS_LABEL);
      this.conversionsIndex = this.input[HEADER_ROW].findIndex((l) => l && l.toUpperCase() === CONVERSIONS_LABEL);
      this.optimalFrequencyIndex = this.input[HEADER_ROW].findIndex(
        (l) => l && l.toUpperCase() === OPTIMAL_FREQUENCY_LABEL
      );
      this.reachIndex = this.input[HEADER_ROW].findIndex((l) => l && l.toUpperCase() === REACH_LABEL);
      this.touchpointIndex = this.input[HEADER_ROW].findIndex((l) => l && l.toUpperCase() === TOUCHPOINT_LABEL);
      this.details = this.extractDetails(input);
      this.sections = this.buildSections(this.details);

      // sugar
      this.getBudget = this.getAggregateMetric('totalBudget');
      this.getTotalBudget = this.getTotalMetric('totalBudget');
      this.getCEOF = this.getAggregateMetric('ceof');
      this.getTotalCEOF = this.getTotalMetric('ceof');
      this.getContacts = this.getAggregateMetric('contacts');
      this.getTotalContacts = this.getTotalMetric('contacts');
      this.getClicks = this.getAggregateMetric('clicks');
      this.getConversions = this.getAggregateMetric('conversions');
      this.getViewableContacts = this.getAggregateMetric('viewableContacts');
      this.getViewableContactsOnTarget = this.getAggregateMetric('viewableContactsOnTarget');
      this.getTotalOptimalFrequency = this.getAggregateMetric('optimalFrequency');

      // aliases
      this.getStructureByType = this.getSectionsByType;
      this.getStructure = this.getSections;
    }
  }

  isReady = () => !!this.input;

  getDimensions = () => {
    return this.input[HEADER_ROW].slice(0, this.flightStartIndex).map((label, index) => ({ id: index + 1, label }));
  };

  getIdentifiableDimensions = () => {
    return this.input[HEADER_ROW].slice(0, this.flightEndIndex + 1).map((label, index) => ({ id: index + 1, label }));
  };

  getMetrics = () => {
    return this.input[HEADER_ROW].slice(this.flightEndIndex + 1).map((label, index) => ({ id: index + 1, label }));
  };

  getSummableMetrics = () => {
    return this.getMetrics().filter((m) => SUMMABLE_METRIC_LABELS.includes(m.label));
  };

  getDepth = () => {
    return this.flightStartIndex;
  };

  extractDetails = (input) => {
    const details = [];
    // using a for loop to be able to quickly exit when reading an empty line
    for (let index = 1, len = input.length; index < len; index++) {
      const row = input[index];
      // if empty row, exit quickly
      if (row.length === 0) {
        // Next row should be "Legend", if not, alert user!
        if (input[index + 1] && input[index + 1][0] !== 'Legend') {
          alert(
            'We expected to find `Legend` after an empty line in first column. Please double check the XLS before saving!'
          );
        }
        return details;
      }
      // if we have data in first cell
      if (row[0] && row[0].length > 0) {
        details.push({
          data: row,
          ...this.parseRow(row),
        });
      }
    }

    return details;
  };

  buildSections = (details) => {
    const dimensions = this.getIdentifiableDimensions();
    const sections = [];

    // for each line
    details.forEach((row) => {
      let parent = null;
      dimensions.forEach((dimension, index) => {
        const label = row.data[index];
        const parentId = parent ? parent.id : null;
        // build section
        const section = {
          id: `${parentId}>${label}`,
          parentId,
          type: dimension.label,
          getBudget: () => this.getBudget({ id: `${parentId}>${label}` }),
          getCEOF: () => this.getCEOF({ id: `${parentId}>${label}` }),
          getContacts: () => this.getContacts({ id: `${parentId}>${label}` }),
          getClicks: () => this.getClicks({ id: `${parentId}>${label}` }),
          getViewableContacts: () => this.getViewableContacts({ id: `${parentId}>${label}` }),
          getViewableContactsOnTarget: () => this.getViewableContactsOnTarget({ id: `${parentId}>${label}` }),
          getConversions: () => this.getConversions({ id: `${parentId}>${label}` }),
          label,
          labelWithParent: parent ? `<b>${label}</b><br/>(${parent.label})` : `<b>${label}</b>`,
        };
        // add this section if it doesn't exist yet
        if (!sections.find((s) => s.id === section.id)) {
          sections.push(section);
        }
        // this begins the new parent
        parent = section;
      });
    });

    return sections;
  };

  // we want the set the date as UTC noon to force Date.parse to skip the local timezone
  parseDate = (date) => {
    return `${date} 12:00:00 UTC`;
  };

  parseAmount = (amount) => {
    return numeral(amount).value();
  };

  parseNumber = (number) => {
    return numeral(number).value();
  };

  composeSectionId = (row) => {
    return `null>${row.slice(0, this.getIdentifiableDimensions().length).join('>')}`;
  };

  parseRow = (row) => {
    const optimalFrequency = Math.max(this.parseNumber(row[this.optimalFrequencyIndex]), 0.1);

    return {
      flightStart: this.parseDate(row[this.flightStartIndex]),
      flightEnd: this.parseDate(row[this.flightEndIndex]),
      totalBudget: this.parseAmount(row[this.totalBudgetIndex]),
      ceof: this.parseNumber(row[this.ceofIndex]),
      contacts: this.parseNumber(row[this.contactsIndex]),
      viewableContacts: this.parseNumber(row[this.viewableContactsIndex]),
      viewableContactsOnTarget: this.parseNumber(row[this.viewableContactsOnTargetIndex]),
      clicks: this.parseNumber(row[this.clicksIndex]),
      conversions: this.parseNumber(row[this.conversionsIndex]),
      sectionId: this.composeSectionId(row),
      optimalFrequency: optimalFrequency,
      reach: this.reachIndex > -1 ? this.parseNumber(row[this.reachIndex]) : null,
      touchpoint: this.touchpointIndex > -1 ? row[this.touchpointIndex] : null,
    };
  };

  // read each row having some data within first cell
  // until reading an empty line ( which should precede Legend )
  getDetails = () => {
    return this.details;
  };

  getSections = () => {
    return this.sections;
  };

  getChildren = (parent) => {
    return this.sections.filter((s) => s.parentId === parent.id);
  };

  getRoots = () => {
    const root = { id: null };

    return this.getChildren(root);
  };

  getChildrenByType = (parent, type) => {
    const children = this.getChildren(parent);
    const directChildren = children.filter((n) => n.type === type);

    if (directChildren.length === 0) {
      return flatten(children.map((c) => this.getChildrenByType(c, type)));
    }

    return directChildren;
  };

  getSectionsByType = (type) => {
    return this.sections.filter((s) => s.type === type);
  };

  getTotalMetric = (metric) => () => {
    return sumBy(this.details, metric);
  };

  getAggregateMetric = (metric) => (section) => {
    const children = this.getChildren(section);

    if (children.length === 0) {
      const detail = this.details.find((d) => d.sectionId === section.id);

      return detail
        ? typeof metric === 'string'
          ? detail[metric]
          : this.parseNumber(detail.data[metric + this.flightEndIndex])
        : null;
    }

    return sumBy(children, this.getAggregateMetric(metric));
  };

  getFlightPeriods = (section) => {
    const { id } = section;
    const detail = this.details.find((d) => d.sectionId === id);
    if (detail) {
      return [
        {
          startsOn: detail.flightStart,
          endsOn: detail.flightEnd,
        },
      ];
    } else {
      const flights = flatten(this.getChildren(section).map(this.getFlightPeriods));

      return aggregateFlightPeriods(flights);
    }
  };

  getFlightDates = () => {
    this.flightDates = this.flightDates || [
      min(this.getDetails().map((d) => moment(d.flightStart).format('x'))),
      max(this.getDetails().map((d) => moment(d.flightEnd).format('x'))),
    ];

    return this.flightDates.map((d) => moment.utc(parseInt(d, 10)).format('DD-MMM-YYYY'));
  };
}

function aggregateFlightPeriods(flights) {
  if (flights.length === 0) {
    return [];
  }
  const sorted = sortBy(flights, [
    function (f) {
      return Date.parse(f.startsOn);
    },
  ]);

  return reducedFlightPeriods(sorted[0], sorted.slice(1));
}

function reducedFlightPeriods(currentFlight, flights) {
  if (!flights || flights.length === 0) {
    return [currentFlight];
  }
  const nextFlight = flights[0];
  // if there is a gap between periods
  if (Date.parse(nextFlight.startsOn) - Date.parse(currentFlight.endsOn) > ONE_DAY_IN_MILLISECONDS) {
    return flatten([currentFlight, reducedFlightPeriods(nextFlight, flights.slice(1))]);
  } else {
    // use this nextFlight end date only if after current one
    if (Date.parse(nextFlight.endsOn) > Date.parse(currentFlight.endsOn)) {
      currentFlight.endsOn = nextFlight.endsOn;
    }
    return reducedFlightPeriods(currentFlight, flights.slice(1));
  }
}

export default Parser;
