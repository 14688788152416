import React from 'react';
import TrueTargetRatingPoint from '../../components/TrueTargetRatingPoint';

export default function TrueTargetRatingPointPreview() {
  const props = {
    rating: 421,
  };

  return <TrueTargetRatingPoint {...props} />;
}
