import { Col, Row } from 'reactstrap';
import React, { useContext } from 'react';

import AccessContext from '../../contexes/AccessContext';
import { Number } from 'happy-react-lib';
import PropTypes from 'prop-types';
import { isFinancialLabel } from './index';

function SunburstNav({ point, label }) {
  const { currency } = useContext(AccessContext);

  return (
    <Row>
      <Col>
        <dl>
          <dt>Dimension</dt>
          <dd>{point.type}</dd>
          <dt>{label}</dt>
          <dd>
            {isFinancialLabel(label) && currency}
            <Number value={point.value} />
          </dd>
        </dl>
      </Col>
      <Col>
        <dl>
          <dt>Contacts</dt>
          <dd>
            <Number value={point.contacts} />
          </dd>
          <dt>CEOF</dt>
          <dd>
            <Number value={point.ceof} />
          </dd>
        </dl>
      </Col>
    </Row>
  );
}

SunburstNav.propTypes = {
  label: PropTypes.string.isRequired,
  point: PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    ceof: PropTypes.number.isRequired,
    contacts: PropTypes.number.isRequired,
  }),
};

export default SunburstNav;
