import { Col, Row } from 'reactstrap';

import { Number } from 'happy-react-lib';
import PropTypes from 'prop-types';
import React from 'react';

TouchpointsReachList.propTypes = {
  touchpoints: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  selection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
};

export default function TouchpointsReachList({ touchpoints, selection }) {
  return touchpoints.map((t) => {
    const active = selection.find((st) => st.id === t.id) || {};

    return <Touchpoint key={t.id} {...t} {...active} active={!!active.id} />;
  });
}

function Touchpoint({ label, description, active, peopleReached, color }) {
  const textColor = active ? color : '#E4E4E7';
  const words = description.split(' ').slice(1).join(' ');
  const reached = active ? <Number value={peopleReached} /> : 'No';

  return (
    <Row style={{ color: textColor, marginBottom: '.75em' }}>
      <Col xs={2}>{label}</Col>
      <Col>
        {reached} {active ? 'people' : 'one'} {words}
      </Col>
    </Row>
  );
}
