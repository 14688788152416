import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import Chart from './Chart';
import { DARK_GRAY_COLOR } from 'happy-react-lib/build/helpers/colors';
import FlattenToolbar from './FlattenToolbar';
import { NumbersHelper } from 'happy-react-lib';
import React from 'react';
import TabsNav from '../TabsNav';
import deduplicatedIndexes from '../../utils/deduplicatedIndexes';
import flatten from 'lodash/flatten';
import moment from 'moment';
import uniq from 'lodash/uniq';

class FlightDates extends React.Component {
  state = {
    dimension: {},
    flattened: true,
  };

  constructor(props) {
    super(props);

    this.dimensions = props.mediaPlan.getDimensions();
    if (this.dimensions && this.dimensions.length > 0) {
      this.state.dimension = this.dimensions[0];
    }
    this.today = Date.now();
  }

  partialFill = (beginning, end) => {
    // full bar if today after end
    if (this.today >= end) {
      return 1;
    }
    // empty bar if today before beginning or empty period
    if (this.today <= beginning || end - beginning <= 0) {
      return 0;
    }
    // fill %
    return (this.today - beginning) / (end - beginning);
  };

  tooltipFormatter() {
    const startsOn = moment(this.x).format('DD-MMM-YYYY');
    const endsOn = moment(this.x2).format('DD-MMM-YYYY');
    const totalFlightDays = moment(this.x2).diff(this.x, 'days');
    let flightDays = 0;
    const today = Date.now();
    if (this.x2 < today) {
      flightDays = totalFlightDays;
    } else {
      if (this.x < today) {
        flightDays = moment(today).diff(this.x, 'days');
      }
    }

    return `<b>${
      this.yCategory
    }</b> <br> - Starts on: ${startsOn} <br> - Ends on: ${endsOn}<br> - Flight days: ${flightDays} out of ${totalFlightDays} (${NumbersHelper.percentage(
      this.point.partialFill.amount
    )})`;
  }

  computeData(dimension) {
    const { mediaPlan } = this.props;
    const nodes = mediaPlan.getStructureByType(dimension.label);

    return {
      dimension: dimension.label,
      categories: nodes.map((n) => n.labelWithParent || n.label),
      periods: flatten(
        nodes.map((n, nIndex) =>
          mediaPlan.getFlightPeriods(n).map((flight) => {
            const startsOn = Date.parse(flight.startsOn);
            const endsOn = Date.parse(flight.endsOn);
            return {
              x: startsOn,
              x2: endsOn,
              y: nIndex,
              partialFill: {
                amount: this.partialFill(startsOn, endsOn),
                fill: DARK_GRAY_COLOR,
              },
            };
          })
        )
      ),
    };
  }

  flatten(data) {
    const { mediaPlan } = this.props;
    const nodes = mediaPlan.getStructureByType(data.dimension);
    const categories = nodes.map((n) => n.label);
    const periodMatrix = deduplicatedIndexes(categories);
    const periods = data.periods.map((p) => ({
      ...p,
      y: periodMatrix[p.y],
    }));

    return {
      dimension: data.dimension,
      categories: uniq(categories),
      periods,
    };
  }

  selectDimension = (dimension) => {
    this.setState({ dimension });
  };

  setFlattened = (flattened) => this.setState({ flattened });

  render() {
    let data = this.computeData(this.state.dimension);
    if (this.state.flattened) {
      data = this.flatten(data);
    }

    return (
      <Card>
        <CardHeader>
          <Row>
            <Col>Flight dates</Col>
            <Col style={{ textAlign: 'right' }}>
              <FlattenToolbar
                flattened={this.state.flattened}
                onClick={this.setFlattened}
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody style={{ marginTop: '1.25rem', padding: '0.75rem 1.25rem' }}>
          <TabsNav
            items={this.dimensions}
            activeItem={this.state.dimension}
            onClick={this.selectDimension}
          />
          <div className="mt-3">
            <Chart
              {...data}
              tooltipFormatter={this.tooltipFormatter}
              title=""
            />
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default FlightDates;
