import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import sum from 'lodash/sum';

export default function ReachAvailabilityChart({ data }) {
  const availableReach = data.map((_, index) => sum(data.slice(index, -1)));
  const options = {
    chart: {
      type: 'spline',
    },
    title: null,
    xAxis: {
      tickInterval: 1,
      title: {
        text: 'Frequency',
      },
    },
    yAxis: {
      max: 100,
      labels: {
        format: '{value:.0f}%',
      },
      title: { text: 'Coverage' },
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y:.0f}% of the audience covered with a frequency of {point.x}',
    },
    series: [
      {
        name: 'Coverage',
        data: availableReach.map((d) => d * 100),
        color: '#293113',
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
