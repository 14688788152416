import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
require('highcharts/modules/heatmap')(Highcharts);

class HeatMapChart extends React.Component {
  render() {
    const config = {
      chart: {
        type: 'heatmap',
        borderColor: '#f00',
      },
      title: {
        text: this.props.title,
      },
      credits: { enabled: false },
      xAxis: {
        categories: this.props.xCategories,
        lineColor: '#fff',
        tickLength: 0,
        opposite: true,
        labels: {
          rotation: -90,
        },
      },
      yAxis: {
        categories: this.props.yCategories,
        lineColor: '#fff',
        gridLineColor: '#fff',
        reversed: true,
        title: { text: '' },
      },
      colorAxis: {
        min: 0,
        minColor: '#FFF',
        maxColor: '#000',
      },
      tooltip: {
        backgroundColor: 'rgba(255,255,255, 1)',
        useHTML: true,
        formatter: this.props.tooltipFormatter,
      },
      series: [
        {
          borderWidth: 1,
          borderColor: '#fff',
          data: this.props.data,
        },
      ],
      plotOptions: {
        heatmap: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    };

    return <HighchartsReact highcharts={Highcharts} options={config} />;
  }
}

export default HeatMapChart;
