import './input_range.css';

import PropTypes from 'prop-types';
import React from 'react';

function Factor({ current, callback, edges }) {
  const min = Math.floor(edges.stepsCount / 2) * -edges.stepsGap;
  const max = -min;

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 2fr',
        columnGap: '1em',
      }}
    >
      <span
        style={{
          textAlign: 'right',
          opacity: opacity(current * -1, max),
        }}
      >
        {edges.lowEndLabel}
      </span>
      <input
        type="range"
        min={min}
        max={max}
        step={edges.stepsGap}
        value={current}
        title={edges.description}
        onChange={(e) => callback(parseFloat(e.target.value, 10))}
      ></input>
      <span
        style={{
          textAlign: 'left',
          opacity: opacity(current, max),
        }}
      >
        {edges.highEndLabel}
      </span>
    </div>
  );
}

const MIN_OPACITY = 0.4;
// compute opacity between 0.4 and 1
function opacity(offset, max) {
  if (offset > 0) {
    return MIN_OPACITY + (offset / max) * (1 - MIN_OPACITY);
  }

  return MIN_OPACITY;
}

Factor.propTypes = {
  current: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
  edges: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lowEndLabel: PropTypes.string.isRequired,
    highEndLabel: PropTypes.string.isRequired,
    stepsCount: PropTypes.number.isRequired,
    stepsGap: PropTypes.number.isRequired,
  }),
};

export default Factor;
