import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';

// TODO: use touchpoint viewability from Happy
const TOUCHPOINTS_QUERY = gql`
  query touchpoints {
    touchpoints {
      id
      icon
      iconUrl
      negatedIconUrl
      label
      description
      defaultCpm
      defaultDataCapture
      defaultDataTargeting
      defaultTradingAgility
      defaultOptimalFrequency
      defaultCreativeOptimization
      onTargetPercentage
      coverageOverlap
      percentageOfViewability
    }
  }
`;

export default graphql(TOUCHPOINTS_QUERY);
