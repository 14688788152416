import '../App.css';

import { Button, ButtonGroup, Col, Container, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import CampaignPlanSharedHeaderPreview from './CampaignPlanSharedHeaderPreview';
import ColumnChartPreview from './ColumnChartPreview';
import DeliveryReachPreview from './DeliveryReachPreview';
import EstimatedDelivery from './EstimatedDeliveryPreview';
import FunnelPreview from './FunnelPreview';
import HeatMapPreview from './HeatMapPreview';
import MultiTouchpointsReachPreview from './MultiTouchpointsReachPreview';
import OstrowModelPreview from './OstrowModelPreview';
import TacticCostsPreview from './TacticCostsPreview';
import TacticNotesPreview from './TacticNotesPreview';
import TouchpointsModalPreview from './TouchpointsModalPreview';
import TouchpointsReachListPreview from './TouchpointsReachListPreview';
import TrueTargetRatingPointPreview from './TrueTargetRatingPointPreview';

const previews = [
  {
    label: 'Shared header',
    component: CampaignPlanSharedHeaderPreview,
  },
  {
    label: 'Touchpoints modal',
    component: TouchpointsModalPreview,
  },
  {
    label: 'HeatMap',
    component: HeatMapPreview,
  },
  {
    label: 'TacticCosts',
    component: TacticCostsPreview,
  },
  {
    label: 'Frequency estimate',
    component: OstrowModelPreview,
  },
  {
    label: 'Funnel',
    component: FunnelPreview,
  },
  {
    label: 'Column',
    component: ColumnChartPreview,
  },
  {
    label: 'Estimated Delivery',
    component: EstimatedDelivery,
  },
  {
    label: 'True target rating point',
    component: TrueTargetRatingPointPreview,
  },
  {
    label: 'Delivery reach',
    component: DeliveryReachPreview,
  },
  {
    label: 'Tactic Notes',
    component: TacticNotesPreview,
  },
  {
    label: 'MultiTouchpoints Reach',
    component: MultiTouchpointsReachPreview,
  },
  {
    label: 'Touchpoints Reach',
    component: TouchpointsReachListPreview,
  },
];

function Previews() {
  const [currentPreview, setCurrentPreview] = useState(null);
  useEffect(() => {
    const p = localStorage.getItem('preview_index');
    if (p !== null) {
      setCurrentPreview(parseInt(p, 10));
    }
  }, []);
  const CurrentComponent = currentPreview != null && previews[currentPreview].component;
  const selectComponent = (index) => {
    localStorage.setItem('preview_index', index);
    setCurrentPreview(index);
  };

  return (
    <main className="pt-3">
      <Container>
        <Row>
          <Col md={3}>
            <ButtonGroup className="mb-3" vertical>
              {previews.map((preview, index) => (
                <Button key={index} outline active={index === currentPreview} onClick={() => selectComponent(index)}>
                  {preview.label}
                </Button>
              ))}
            </ButtonGroup>
          </Col>
          <Col md={9}>{CurrentComponent && <CurrentComponent />}</Col>
        </Row>
      </Container>
    </main>
  );
}

export default Previews;
