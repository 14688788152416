import { ColorsHelper } from 'happy-react-lib';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { COLOR_PALETTE } from '../../utils/colors';
require('highcharts/modules/venn')(Highcharts);

// turboThreshold set to 0 to remove the limitation on the length of data
// Because of a potential large set of combinations, we want to ensure
// Highcharts always try to display all data
// with 10 audiences, we have more that 1000 (default limit) of intersections

export default function Venn({ data }) {
  const options = {
    legend: { enabled: true },
    colors: COLOR_PALETTE,
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.name}: {point.value:,.0f} people',
    },
    plotOptions: {
      venn: {
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
      },
      series: {
        turboThreshold: 0,
      },
    },
    series: [
      {
        type: 'venn',
        name: 'Overlap',
        data,
      },
    ],
    title: null,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
