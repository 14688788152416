import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';

import App from './App';
import Highcharts from 'highcharts';
import OstrowModelPage from './OstrowModelPage';
import Previews from './previews';
import React from 'react';
import ReactDOM from 'react-dom';
import faChartPie from '@fortawesome/fontawesome-free-solid/faChartPie';
import faCopy from '@fortawesome/fontawesome-free-solid/faCopy';
import faDownload from '@fortawesome/fontawesome-free-solid/faDownload';
import faRandom from '@fortawesome/fontawesome-free-solid/faRandom';
import faRoad from '@fortawesome/fontawesome-free-solid/faRoad';
import faSun from '@fortawesome/fontawesome-free-solid/faSun';
import faTable from '@fortawesome/fontawesome-free-solid/faTable';
import faTh from '@fortawesome/fontawesome-free-solid/faTh';
import faUpload from '@fortawesome/fontawesome-free-solid/faUpload';
import fontawesome from '@fortawesome/fontawesome';
import moment from 'moment-timezone';
import HighchartsExporting from 'highcharts/modules/exporting';

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts);
}

Highcharts.setOptions(
  {
    credits: { enabled: false },
    legend: { enabled: false },
    chart: {
      style: {
        fontFamily: 'var(--font-family-sans-serif)',
      },
    },
    title: {
      align: 'left',
      style: {
        color: '#212529',
        fontSize: '1rem',
      },
      x: -10, // counter part chart.spacingLeft of 10 by default
    },
    plotOptions: {
      series: {
        dataLabels: {
          style: {
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0px',
          },
        },
      },
    },
  },
  []
);

const token = window.__INITIAL_STATE && window.__INITIAL_STATE.userToken;

const headers = {
  Authorization: `Bearer ${token}`,
  'X-CSRF-Token': token,
  'X-Requested-With': 'XMLHttpRequest',
};

const client = new ApolloClient({
  link: new HttpLink({ uri: '/graphql', headers }),
  cache: new InMemoryCache({}),
});

// Init FontAwesome
const icons = [faChartPie, faCopy, faDownload, faRandom, faRoad, faSun, faTable, faTh, faUpload];
fontawesome.library.add(...icons);

// set momentjs default timezone to GMT
moment.tz.setDefault('GMT');

const Page = window.location.pathname.match(/__previews/)
  ? Previews
  : window.__INITIAL_STATE && window.__INITIAL_STATE.ostrowModelOnly
  ? OstrowModelPage
  : App;

ReactDOM.render(
  <ApolloProvider client={client}>
    <Page initialState={window.__INITIAL_STATE} {...window.__INITIAL_STATE} />
  </ApolloProvider>,
  document.getElementById('root')
);
