import React, { useContext } from 'react';

import AccessContext from '../contexes/AccessContext';
import { Amount } from 'happy-react-lib';

const AmountWithCurrency = ({ value, showDecimals = false }) => {
  const { currency } = useContext(AccessContext);

  return value === 0 ? (
    '-'
  ) : (
    <Amount currency={currency} value={value} showDecimals={showDecimals} />
  );
};

export default AmountWithCurrency;
