import PropTypes from 'prop-types';
import styled from 'styled-components';

const Section = styled.div`
  margin-bottom: ${({ last }) => (last ? '0' : '3rem')};
`;

Section.propTypes = {
  last: PropTypes.bool,
};

Section.defaultProps = {
  last: false,
};

export default Section;
