import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: left;
  font-size: 8pt;
  padding: 0 15px 15px;

  a {
    color: var(--danger);
  }
`;

const HappyficationDataDisclaimer = () => (
  <Wrapper>
    <hr />
    <p>
      The happyfication platform stores basic informations about its users to be
      able to provide personalized services.
      <br />
      Please review our{' '}
      <a href="https://happyfication.com/privacy-policy/" target="blank">
        privacy policy
      </a>
      ,{' '}
      <a href="https://happyfication.com/user-agreement/" target="blank">
        user agreement
      </a>{' '}
      and of course feel free to{' '}
      <a href="https://happyfication.com/remove-my-data" target="blank">
        opt out
      </a>
      .
    </p>
  </Wrapper>
);

export default HappyficationDataDisclaimer;
