export default function touchpointLabelMatcher(label, touchpointLabel) {
  return label === touchpointLabel || hasAMatch(label, touchpointLabel);
}

function hasAMatch(label, tLabel) {
  return map[label] && map[label].includes(tLabel);
}

const map = {
  Television: ['TV'],
  'Television Ecosystem': ['TV Ecosystem'],
  Press: ['Printed Press'],
  Video: ['Video in-stream'],
  Mobile: ['Mobile in-app'],
  Display: ['Display in-web'],
  'Social Media': ['Social'],
};

export const reversedMap = Object.keys(map).reduce((acc, oldLabel) => {
  map[oldLabel].forEach((newLabel) => {
    acc[newLabel] = oldLabel;
  });

  return acc;
}, {});
