import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { Component } from 'react';

import AccessContext from '../../contexes/AccessContext';
import FieldGroup from '../FieldGroup';
import HappyficationDataDisclaimer from '../HappyficationDataDisclaimer';
import { Mutation } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';

function isValidEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function formatDateTime(date) {
  return date.toLocaleString();
}

const ADD_VALIDATION = gql`
  mutation AddValidation(
    $commentableId: Int!
    $name: String!
    $email: String!
  ) {
    addValidation(
      commentableType: "CampaignPlan"
      commentableId: $commentableId
      name: $name
      email: $email
    ) {
      name
      email
    }
  }
`;

class ValidationModal extends Component {
  state = {
    name: null,
    email: null,
    errors: null,
  };

  static propTypes = {
    campaignPlanId: PropTypes.number.isRequired,
    validated: PropTypes.bool.isRequired,
    toggleValidationModal: PropTypes.func.isRequired,
    updateStatus: PropTypes.func.isRequired,
  };

  submitForm = (event, gqlMutation) => {
    const { name, email } = this.state;
    const errors = { empty: true };

    event.preventDefault();
    if (name === null || name === '') {
      errors.name = 'error';
      errors.empty = false;
    }
    if (!isValidEmail(email)) {
      errors.email = 'error';
      errors.empty = false;
    }

    if (errors.empty) {
      gqlMutation({
        variables: {
          commentableId: this.props.campaignPlanId,
          name,
          email,
        },
      });
      this.props.toggleValidationModal();
      this.props.updateStatus(2);
    } else {
      this.setState({ errors });
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const { name, value } = target;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { validated, validating, toggleValidationModal } = this.props;

    const { errors } = this.state;

    return (
      <Modal isOpen={!validated} toggle={toggleValidationModal}>
        <ModalHeader toggle={toggleValidationModal}>
          Campaign plan validation
        </ModalHeader>

        <form>
          <ModalBody>
            <p>
              By validating this form, you electronically sign the campaign
              plan:
            </p>
            <AccessContext.Consumer>
              {({ slug, name, brand }) => (
                <ul>
                  <li>Brand: {brand}</li>
                  <li>Campaign plan: {name}</li>
                  <li>
                    Reference: <code>{slug}</code>
                  </li>
                  <li>Timestamp: {formatDateTime(new Date())}</li>
                </ul>
              )}
            </AccessContext.Consumer>
            <FieldGroup
              id="validatorName"
              name="name"
              type="text"
              label="Name"
              state={errors && errors.name}
              placeholder="Enter your name"
              onChange={this.handleInputChange}
            />
            <FieldGroup
              id="validatorEmail"
              name="email"
              type="email"
              label="Email"
              state={errors && errors.email}
              placeholder="Enter your email"
              onChange={this.handleInputChange}
            />
            {validating && <p className="text-center">Validating...</p>}
          </ModalBody>

          {!validating && (
            <React.Fragment>
              <ModalFooter>
                <Mutation mutation={ADD_VALIDATION}>
                  {(addValidation) => (
                    <Button
                      type="submit"
                      onClick={(event) => this.submitForm(event, addValidation)}
                      outline
                      color="success"
                    >
                      Validate
                    </Button>
                  )}
                </Mutation>
              </ModalFooter>
              <HappyficationDataDisclaimer />
            </React.Fragment>
          )}
        </form>
      </Modal>
    );
  }
}

export default ValidationModal;
