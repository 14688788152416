import AccessContext from '../contexes/AccessContext';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const H1 = styled.h1`
  margin-bottom: 3rem;
`;
const H2 = styled.h2`
  margin-bottom: 3rem;
`;

const PlanningHeader = ({ brand, name, color }) => (
  <AccessContext.Consumer>
    {({ readOnly }) =>
      readOnly && (
        <H1>
          <FontAwesomeIcon icon="road" /> Campaign plan
          <p className="my-1" style={{ color }}>
            {brand}
          </p>
          <p style={{ color }}>{name}</p>
        </H1>
      )
    }
  </AccessContext.Consumer>
);

PlanningHeader.propTypes = {
  brand: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
};

PlanningHeader.defaultProps = {
  color: '#333',
};

export default PlanningHeader;
