import { Col, Row } from 'reactstrap';

import CoverageStrength from './CoverageStrength';
import EditOnly from '../EditOnly';
import FrameworkOptimization from './FrameworkOptimization';
import PropTypes from 'prop-types';
import React from 'react';
import TacticInvestment from './TacticInvestment';
import TagNameContext from '../../contexes/TagNameContext';
import TargetAudience from './TargetAudience';
import TouchpointsSelection from './TouchpointsSelection';

class TacticAnalysis extends React.Component {
  state = {
    targetAudience: null,
    targetAudienceSize: 0,
  };

  static propTypes = {
    id: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    investment: PropTypes.number.isRequired,
    ceof: PropTypes.number.isRequired,
    section: PropTypes.object.isRequired,
    budgetSplit: PropTypes.number.isRequired,
    baseAudience: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    touchpoints: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
  };

  constructor(props) {
    super(props);

    if (props.initialTargetAudience) {
      this.state.targetAudience = props.initialTargetAudience;
    }
  }

  setTargetAudience = (targetAudience) => {
    if (!targetAudience) {
      this.setState({ targetAudience });
    } else {
      const currentTarget = this.state.targetAudience;
      if (!currentTarget || currentTarget.id !== targetAudience.id) {
        this.setState({ targetAudience });
      }
    }
  };

  setTargetAudienceSize = (targetAudienceSize) => {
    this.setState({ targetAudienceSize });
  };

  render() {
    const { id, index, name, ceof, investment, budgetSplit, totalOptimalFrequency, section, mediaPlan } = this.props;
    const tactic = {
      id,
      name,
      ceof,
      investment,
      budgetSplit,
      section,
      totalOptimalFrequency,
    };
    const details = mediaPlan.details.filter((d) => d.sectionId.startsWith(tactic.section.id));

    return (
      <React.Fragment>
        <TagNameContext.Consumer>
          {(inputs) => (
            <Row>
              <Col>
                <TacticInvestment
                  {...tactic}
                  activationStrategyId={
                    inputs &&
                    inputs.campaign_plan_tactics &&
                    inputs.campaign_plan_tactics[this.props.index] &&
                    inputs.campaign_plan_tactics[this.props.index].activationStrategy.value
                  }
                />
              </Col>
              <EditOnly>
                <Col md={6}>
                  <FrameworkOptimization
                    index={index}
                    initialActivationStrategyId={
                      inputs &&
                      inputs.campaign_plan_tactics &&
                      inputs.campaign_plan_tactics[this.props.index] &&
                      inputs.campaign_plan_tactics[this.props.index].activationStrategy.value
                    }
                  />
                </Col>
              </EditOnly>
            </Row>
          )}
        </TagNameContext.Consumer>

        <TagNameContext.Consumer>
          {(inputs) => (
            <TargetAudience
              index={index}
              baseAudience={this.props.baseAudience}
              audience={this.state.targetAudience}
              setAudience={this.setTargetAudience}
              setAudienceSize={this.setTargetAudienceSize}
              initialEstimatedAudienceSize={
                inputs &&
                inputs.campaign_plan_tactics &&
                inputs.campaign_plan_tactics[this.props.index] &&
                inputs.campaign_plan_tactics[this.props.index].estimatedTargetSize.value
              }
            />
          )}
        </TagNameContext.Consumer>

        <TagNameContext.Consumer>
          {(inputs) => (
            <React.Fragment>
              <TouchpointsSelection
                index={index}
                selection={this.props.touchpoints}
                initialRationaleContent={
                  inputs &&
                  inputs.campaign_plan_tactics &&
                  inputs.campaign_plan_tactics[this.props.index] &&
                  inputs.campaign_plan_tactics[this.props.index].rationaleContent.value
                }
                initialThresholdContent={
                  inputs &&
                  inputs.campaign_plan_tactics &&
                  inputs.campaign_plan_tactics[this.props.index] &&
                  inputs.campaign_plan_tactics[this.props.index].thresholdContent &&
                  inputs.campaign_plan_tactics[this.props.index].thresholdContent.value
                }
                initialBenchmarksContent={
                  inputs &&
                  inputs.campaign_plan_tactics &&
                  inputs.campaign_plan_tactics[this.props.index] &&
                  inputs.campaign_plan_tactics[this.props.index].benchmarksContent.value
                }
              />
              <CoverageStrength
                index={index}
                audienceName={this.state.targetAudience ? this.state.targetAudience.name : ''}
                section={this.props.section}
                reach={this.props.ceof}
                budget={this.props.investment}
                totalOptimalFrequency={this.props.totalOptimalFrequency}
                audienceSize={this.state.targetAudienceSize}
                selection={this.props.touchpoints}
                details={details}
                initialAvgOptimalFrequency={
                  inputs &&
                  inputs.campaign_plan_tactics &&
                  inputs.campaign_plan_tactics[this.props.index] &&
                  parseFloat(inputs.campaign_plan_tactics[this.props.index].avgOptimalFrequency.value, 10)
                }
                initialEstimatedReach={
                  inputs &&
                  inputs.campaign_plan_tactics &&
                  inputs.campaign_plan_tactics[this.props.index] &&
                  inputs.campaign_plan_tactics[this.props.index].estimatedReach.value
                }
                initialTrueTargetRatingPoint={
                  inputs &&
                  inputs.campaign_plan_tactics &&
                  inputs.campaign_plan_tactics[this.props.index] &&
                  inputs.campaign_plan_tactics[this.props.index].trueTargetRatingPoint &&
                  inputs.campaign_plan_tactics[this.props.index].trueTargetRatingPoint.value
                }
              />
            </React.Fragment>
          )}
        </TagNameContext.Consumer>
      </React.Fragment>
    );
  }
}

export default TacticAnalysis;
