import 'react-select/dist/react-select.css';

import { CardText, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';
import { toSelectOptions, usableAsTargetAudience } from '../../utils/audiences';

import AccessContext from '../../contexes/AccessContext';
import Audience from './Audience';
import { Number } from 'happy-react-lib';
import PropTypes from 'prop-types';
import React from 'react';
import Section from '../Section';
import Select from 'react-select';
import TagNameContext from '../../contexes/TagNameContext';
import loadAudiences from '../../queries/audiences';

class TargetAudience extends React.Component {
  static propTypes = {
    baseAudience: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    index: PropTypes.number.isRequired,
    initialEstimatedAudienceSize: PropTypes.number,
    audience: PropTypes.object,
    setAudience: PropTypes.func.isRequired,
    setAudienceSize: PropTypes.func.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      audiences: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  state = {
    estimatedAudienceSize: 0,
    modalOpen: false,
  };

  constructor(props) {
    super(props);

    if (props.initialEstimatedAudienceSize) {
      this.state.estimatedAudienceSize = props.initialEstimatedAudienceSize;
      this.props.setAudienceSize(this.state.estimatedAudienceSize);
    }
  }

  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen });

  setEstimatedAudienceSize = ({ target }) => {
    const estimatedAudienceSize = parseInt(target.value, 10) || 0;
    this.setState({ estimatedAudienceSize }, () => this.props.setAudienceSize(estimatedAudienceSize));
  };

  audienceSelected = (option) => {
    const audience = option
      ? {
          id: option.value,
          name: option.label,
        }
      : null;

    this.props.setAudience(audience);
  };

  selectedAudience = () => {
    if (this.props.audience) {
      return {
        value: this.props.audience.id,
        label: this.props.audience.name,
      };
    }

    return null;
  };

  render() {
    const { data } = this.props;
    if (data.loading) {
      return null;
    }
    const audiences = toSelectOptions(data.audiences);
    const computeAudienceSize = this.props.baseAudience && this.props.audience;

    return (
      <Section>
        <CardTitle tag="h5">Audience</CardTitle>
        <CardText tag="div">
          <AccessContext.Consumer>
            {({ readOnly, clientPatterns }) =>
              !readOnly && (
                <TagNameContext.Consumer>
                  {(inputs) => (
                    <React.Fragment>
                      <input
                        type="hidden"
                        name={
                          (inputs &&
                            inputs.campaign_plan_tactics &&
                            inputs.campaign_plan_tactics[this.props.index] &&
                            inputs.campaign_plan_tactics[this.props.index].targetAudience.tagName) ||
                          `campaign_plan[campaign_plan_tactics_attributes][${this.props.index}][gwi_target_audience_id]`
                        }
                        value=""
                      />
                      <Select
                        className="mb-2"
                        value={this.selectedAudience()}
                        onChange={this.audienceSelected}
                        name={
                          (inputs &&
                            inputs.campaign_plan_tactics &&
                            inputs.campaign_plan_tactics[this.props.index] &&
                            inputs.campaign_plan_tactics[this.props.index].targetAudience.tagName) ||
                          `campaign_plan[campaign_plan_tactics_attributes][${this.props.index}][gwi_target_audience_id]`
                        }
                        options={usableAsTargetAudience(audiences, clientPatterns)}
                      />
                    </React.Fragment>
                  )}
                </TagNameContext.Consumer>
              )
            }
          </AccessContext.Consumer>
          {computeAudienceSize ? (
            <Audience
              baseAudience={this.props.baseAudience}
              audience={this.props.audience}
              setAudienceSize={this.props.setAudienceSize}
              index={this.props.index}
            />
          ) : (
            <React.Fragment>
              <Row style={{ padding: '0.75rem 0 0' }}>
                <AccessContext.Consumer>
                  {({ readOnly }) =>
                    !readOnly && (
                      <React.Fragment>
                        <Col md={5}>... or enter estimated size:</Col>
                        <Col md={7}>
                          <FormGroup>
                            <TagNameContext.Consumer>
                              {(inputs) => (
                                <Input
                                  type="number"
                                  name={
                                    (inputs &&
                                      inputs.campaign_plan_tactics &&
                                      inputs.campaign_plan_tactics[this.props.index] &&
                                      inputs.campaign_plan_tactics[this.props.index].estimatedTargetSize.tagName) ||
                                    `campaign_plan[campaign_plan_tactics_attributes][${this.props.index}][estimated_target_size]`
                                  }
                                  defaultValue={this.state.estimatedAudienceSize}
                                  onBlur={this.setEstimatedAudienceSize}
                                />
                              )}
                            </TagNameContext.Consumer>
                          </FormGroup>
                        </Col>
                      </React.Fragment>
                    )
                  }
                </AccessContext.Consumer>
              </Row>
              <Row>
                <Col>
                  <p>
                    The estimated size of {this.props.audience ? `"${this.props.audience.name}"` : 'the audience'}{' '}
                    targeted is{' '}
                    <b>
                      <Number value={this.state.estimatedAudienceSize} /> people
                    </b>
                  </p>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </CardText>
      </Section>
    );
  }
}

export default loadAudiences(TargetAudience);
