import { CardText, CardTitle, Col, Row } from 'reactstrap';
import OstrowModel, { Frequency } from './components/OstrowModel';
import React, { useState } from 'react';

import AccessContext from './contexes/AccessContext';
import FrameworkOptimization from './components/PlanOverview/FrameworkOptimization';

const initialState = {
  readOnly: false,
};

function OstrowModelPage({ factorsGroups, initialActivationStrategyId, initialOffsets }) {
  const [offsets, setOffsets] = useState(initialOffsets);
  const [frequency, setFrequency] = useState(0);
  const [strategy, setStrategy] = useState(null);

  return (
    <AccessContext.Provider value={initialState}>
      <Row>
        <Col>
          <CardTitle tag="h5">Estimated optimal frequency</CardTitle>
          <CardText>
            <p style={{ fontSize: '5em', lineHeight: '1em' }}>
              <Frequency value={frequency} />
            </p>
          </CardText>
        </Col>
        <Col className="d-flex justify-content-end">
          <div style={{ width: '25.1em' }}>
            <input name="frequency_estimate[activation_strategy_id]" defaultValue={strategy && strategy.id} type="hidden" />
            <FrameworkOptimization
              index={0}
              callback={setStrategy}
              initialActivationStrategyId={initialActivationStrategyId}
            />
          </div>
        </Col>
      </Row>
      {strategy && (
        <>
          <CardTitle tag="h5">Frequency factors</CardTitle>
        <textarea name="frequency_estimate[offsets]" value={JSON.stringify(offsets)} readOnly hidden />
          <OstrowModel
            factorsGroups={factorsGroups}
            offsets={offsets}
            setOffsets={setOffsets}
            initialFrequency={strategy.estimatedOptimalFrequency}
            frequencyCallback={setFrequency}
          />
        </>
      )}
    </AccessContext.Provider>
  );
}

export default OstrowModelPage;
