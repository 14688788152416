import { CardText, CardTitle } from 'reactstrap';

import AccessContext from '../../contexes/AccessContext';
import PropTypes from 'prop-types';
import React from 'react';
import Section from '../Section';
import Select from 'react-select';
import TacticStrategy from './TacticStrategy';
import TagNameContext from '../../contexes/TagNameContext';
import loadActivationStrategies from '../../queries/activation_strategies';

class FrameworkOptimization extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    initialActivationStrategyId: PropTypes.number,
    callback: PropTypes.func,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      activationStrategies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  state = {
    strategy: null,
  };

  componentWillReceiveProps(newProps) {
    // if newProps give activation strategies back from GraphQL
    // and we have an initialActivationStrategyId through oldProps
    // AND startegy is still null in state
    // initialize it
    const { initialActivationStrategyId, callback } = this.props;
    const { data } = newProps;

    if (
      initialActivationStrategyId &&
      data.activationStrategies &&
      this.state.strategy === null
    ) {
      const strategy = data.activationStrategies.find(
        (s) => s.id === initialActivationStrategyId
      );

      this.setState(
        {
          strategy: {
            ...strategy,
            value: strategy.id,
            label: strategy.title,
          },
        },
        () => {
          if (callback) {
            callback(strategy);
          }
        }
      );
    }
  }

  toSelectOptions = (strategies) => {
    return strategies.map((strategy) => ({
      ...strategy,
      value: strategy.id,
      label: strategy.title,
    }));
  };

  strategySelected = (strategy) => {
    const { callback } = this.props;

    this.setState({ strategy }, () => {
      if (callback) {
        callback(strategy);
      }
    });
  };

  render() {
    const { data } = this.props;
    if (data.loading) {
      return null;
    }
    const strategies = this.toSelectOptions(data.activationStrategies);

    return (
      <Section>
        <CardTitle tag="h5">Optimization focus</CardTitle>
        <CardText tag="div">
          <AccessContext.Consumer>
            {({ readOnly }) =>
              readOnly ? (
                <p>
                  {this.state.strategy
                    ? this.state.strategy.label
                    : 'No focus selected'}
                </p>
              ) : (
                <TagNameContext.Consumer>
                  {(inputs) => (
                    <React.Fragment>
                      <input
                        type="hidden"
                        name={
                          (inputs &&
                            inputs.campaign_plan_tactics &&
                            inputs.campaign_plan_tactics[this.props.index] &&
                            inputs.campaign_plan_tactics[this.props.index]
                              .activationStrategy.tagName) ||
                          `campaign_plan[campaign_plan_tactics_attributes][${this.props.index}][activation_strategy_id]`
                        }
                      />
                      <Select
                        value={this.state.strategy}
                        onChange={this.strategySelected}
                        options={strategies}
                        name={
                          (inputs &&
                            inputs.campaign_plan_tactics &&
                            inputs.campaign_plan_tactics[this.props.index] &&
                            inputs.campaign_plan_tactics[this.props.index]
                              .activationStrategy.tagName) ||
                          `campaign_plan[campaign_plan_tactics_attributes][${this.props.index}][activation_strategy_id]`
                        }
                      />
                    </React.Fragment>
                  )}
                </TagNameContext.Consumer>
              )
            }
          </AccessContext.Consumer>
          {this.state.strategy && (
            <TacticStrategy strategy={this.state.strategy} />
          )}
        </CardText>
      </Section>
    );
  }
}

export default loadActivationStrategies(FrameworkOptimization);
