import React, { useState } from 'react';

import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import SimpleModal from './SimpleModal';
import TouchpointsChart from './PlanOverview/TouchpointsChart';

export default function OptimizationScoreModal({
  allTouchpoints,
  selectedTouchpoints,
}) {
  const [opened, setOpened] = useState(false);
  const toggle = () => setOpened(!opened);

  return (
    <>
      <Button outline onClick={toggle}>
        Optimization score
      </Button>
      <SimpleModal
        open={opened}
        toggle={toggle}
        header="Optimization score"
        size="lg"
      >
        <TouchpointsChart
          touchpoints={allTouchpoints}
          selection={selectedTouchpoints}
        />
      </SimpleModal>
    </>
  );
}

OptimizationScoreModal.propTypes = {
  allTouchpoints: PropTypes.array.isRequired,
  selectedTouchpoints: PropTypes.array.isRequired,
};
