import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import PropTypes from 'prop-types';
import React from 'react';

const SimpleModal = ({ open, header, children, toggle, size }) => (
  <Modal isOpen={open} toggle={toggle} size={size}>
    <ModalHeader>{header}</ModalHeader>
    <ModalBody>{children}</ModalBody>
    <ModalFooter>
      <Button outline onClick={toggle}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
);

SimpleModal.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  toggle: PropTypes.func.isRequired,
  size: PropTypes.string,
  open: PropTypes.bool
};

SimpleModal.defaultProps = {
  size: 'lg',
  open: true
};

export default SimpleModal;
