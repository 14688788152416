import React, { useState } from 'react';

import TacticCosts from '../../components/TacticCosts';

const metrics = {
  1: [
    {
      label: 'Social',
      budget: 36370,
      contacts: 2832343,
      clicks: 33294,
      conversions: null,
    },
  ],
  2: [
    {
      label: 'Facebook',
      budget: 33730,
      contacts: 2832343,
      clicks: 33294,
      conversions: 42,
    },
  ],
  3: [
    {
      label: 'Wallmart',
      budget: 36330,
      contacts: 2832343,
      clicks: 33294,
      conversions: 42,
    },
  ],
};

const dimensions = [
  {
    value: 1,
    label: 'Touchpoint',
  },
  {
    value: 2,
    label: 'Publisher',
  },
  {
    value: 3,
    label: 'Destination',
  },
];

export default function TacticCostsPreview() {
  const [activeDimension, setActiveDimension] = useState(dimensions[0]);
  const props = {
    callback: setActiveDimension,
    activeDimension,
    dimensions,
    metrics: metrics[activeDimension.value],
  };

  return <TacticCosts {...props} />;
}
