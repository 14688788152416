import { Col, Row } from 'reactstrap';

import AudienceLinks from './AudienceLinks';
import AudienceSize from './AudienceSize';
import PropTypes from 'prop-types';
import React from 'react';

class Audience extends React.Component {
  static propTypes = {
    baseAudience: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    setAudienceSize: PropTypes.func.isRequired,
    audience: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    index: PropTypes.number.isRequired,
  };

  render() {
    const { audience, baseAudience, setAudienceSize, index } = this.props;

    return (
      <div>
        <Row>
          <Col>
            <AudienceLinks audience={audience} index={index} />
          </Col>
        </Row>
        <Row>
          <Col>
            <AudienceSize
              baseAudience={baseAudience}
              audience={audience}
              setAudienceSize={setAudienceSize}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Audience;
