import React, { useState } from 'react';

import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';
import SimpleModal from './SimpleModal';

const EMPTY_PATTERN = '<p><br></p>';

export default function NoteThroughModal(props) {
  const { content, onChange, header, cta } = props;
  const [opened, setOpened] = useState(false);
  const [initialContent, setInitialContent] = useState(null);
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  if (initialContent !== content) {
    setValue(
      content
        ? RichTextEditor.createValueFromString(content, 'html')
        : RichTextEditor.createEmptyValue()
    );
    setInitialContent(content);
  }

  const toggleModal = () => {
    if (opened) {
      const html = value.toString('html');
      onChange(html === EMPTY_PATTERN ? '' : html);
    }
    setOpened(!opened);
  };

  return (
    <React.Fragment>
      <Button outline onClick={toggleModal}>
        {cta || header}
      </Button>
      <SimpleModal open={opened} toggle={toggleModal} header={header} size="xl">
        <RichTextEditor value={value} onChange={setValue} readOnly={false} />
      </SimpleModal>
    </React.Fragment>
  );
}

NoteThroughModal.propTypes = {
  content: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  cta: PropTypes.string,
};
