import DeliveryReach from '../components/DeliveryReach';
import React from 'react';

export default function DeliveryReachPreview() {
  const props = {
    audienceName: 'My Audience Name',
    audienceSize: 4200000,
    actual: 2100000,
    threshold: 0.7 * 4200000,
    optimalFrequency: 4.2,
  };

  return <DeliveryReach {...props} />;
}
